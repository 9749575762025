import React from 'react';
import styled from 'styled-components';
import CheckIcon from '../../assets/svgs/CheckIcon';
import { CheckboxFields } from '../../enums/form-elements.enum';
import { ToggleCheckboxPayload } from '../../store/actions/user.action';
import { InputProps } from '../../styles/Input';
import { colors } from '../../styles/Theme';

export interface CheckboxProps extends InputProps {
  isChecked: boolean;
  type: CheckboxFields;
  changeEvent: (value: ToggleCheckboxPayload) => void;
}

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const onCheckboxChange = () => {
    props.changeEvent({
      key: props.type,
    });
  };

  return (
    <CheckboxWrapper onClick={onCheckboxChange} role="checkbox">
      {props.isChecked && <CheckIcon />}
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  width: 25px;
  height: 25px;
  border: 1px solid ${colors.basic.WHITE};
  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 13px;
    height: 12px;
  }
`;
