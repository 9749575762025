import React, { FunctionComponent } from 'react';

const BigGoals: FunctionComponent = () => (
  <svg viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle cx="23.5" cy="33.5" r="11.75" stroke="white" strokeWidth="1.5" />
      <circle cx="24" cy="33" r="3" fill="white" />
      <path
        d="M32 13.9192C29.2527 12.686 26.2065 12 23 12C10.8497 12 1 21.8497 1 34C1 46.1503 10.8497 56 23 56C35.1503 56 45 46.1503 45 34C45 30.8673 44.3452 27.8875 43.165 25.19"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M56.5 12L47 10L45 0.5L37.5 7.5L39 18L48.5 20L56.5 12Z"
        fill="#D90855"
        stroke="#D90855"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24 33L52 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    </g>
  </svg>
);

export default BigGoals;
