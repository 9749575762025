import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AvatarSuccessIcon from '../../assets/svgs/AvatarSucessIcon';
import ListOne from '../../assets/svgs/ListOne';
import ListTwo from '../../assets/svgs/ListTwo';
import Button from '../../components/Button';
import { DefaultLayout } from '../../components/Layout/DefaultLayout';
import { NanoLink, NanoLinkType } from '../../components/Layout/NanoLink';
import SocialLinks from '../../components/SocialLinks';
import { NavigationRoute } from '../../enums/navigation.enum';
import { TextAlign } from '../../enums/text-align.enum';
import Layout from '../../styles/Layout';
import { colors, fonts } from '../../styles/Theme';
import Typography from '../../styles/Typography';

export const Success: React.FC = () => {
  const SuccessContent = (
    <Layout.DefaultSceneWrapper>
      <Typography.NanoSubTitle textAlign={TextAlign.CENTER}>Danke! deine Daten sind</Typography.NanoSubTitle>
      <Typography.NanoTitle lineHeight={72} textAlign={TextAlign.CENTER} marginTop={-20}>
        eingegangen
      </Typography.NanoTitle>
      <ImageContainer>
        <AvatarSuccessIcon />
      </ImageContainer>

      <Typography.NanoBigTextSGSB15 textAlign={TextAlign.CENTER} marginTop={30}>
        Die nächsten Schritte
      </Typography.NanoBigTextSGSB15>

      <NanoListContainer>
        <NanoListElement>
          <ListIconContainer>
            <ListOne />
          </ListIconContainer>
          Wir werden dich so schnell wie möglich kontaktieren, um dich besser kennenzulernen.
        </NanoListElement>
        <NanoListElement>
          <ListIconContainer>
            <ListTwo />
          </ListIconContainer>
          Wenn wir zu einander passen, laden wir dich zu einem persönlichen Kennenlernen ein.
        </NanoListElement>
      </NanoListContainer>

      <Typography.NanoBigTextSGSB15 textAlign={TextAlign.CENTER} marginTop={20} maxWidth={350}>
        Solltest du Fragen haben, kannst du dich natürlich jederzeit bei uns melden!
      </Typography.NanoBigTextSGSB15>

      <NanoLink
        marginTop={24}
        type={NanoLinkType.MAIL}
        text={'karriere@nanogiants.de'}
        target={'mailto:karriere@nanogiants.de?subject=[Next Level Recruiting] Fragen zur Bewerbung'}
      />
      <NanoLink
        marginTop={16}
        type={NanoLinkType.PHONE}
        text={'+49 (0)211 936 727 20 -7'}
        target={'tel:+49211936727207'}
      />
      <Link to={NavigationRoute.COMPANY_MISSION}>
        <Button marginTop={48} label={'Mehr zu den NanoGiants'} />
      </Link>

      <SocialLinks marginTop={56} />
    </Layout.DefaultSceneWrapper>
  );
  return <DefaultLayout content={SuccessContent} />;
};

const ImageContainer = styled.div`
  margin-top: 20px;
  width: 220px;
  display: flex;
`;

const NanoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-top: 5px;
`;

const NanoListElement = styled.div`
  display: flex;
  color: ${colors.basic.WHITE};
  font-size: 16px;
  font-family: ${fonts.SharpGrotesk19};
  line-height: 26px;
`;

const ListIconContainer = styled.div`
  flex: 1;

  padding: 13px;
`;
