import React, { useEffect } from 'react';
import styled from 'styled-components';
import CloseIcon from '../assets/svgs/CloseIcon';
import { colors } from '../styles/Theme';
import Typography from '../styles/Typography';

type PopupProps = {
  title: string;
  isVisible: boolean;
  children: React.ReactNode;
  closePopup: () => void;
};

export const Popup: React.FC<PopupProps> = (props: PopupProps) => {
  useEffect(() => {
    if (props.isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  });

  return (
    <PopupWrapper {...props}>
      <PopupBackground onClick={props.closePopup}></PopupBackground>
      <PopupContent>
        <Typography.NanoSubTitle marginBottom={8} color={colors.primary.nanoBlue}>
          {props.title}
        </Typography.NanoSubTitle>
        <PopupClose onClick={props.closePopup}>
          <CloseIcon />
        </PopupClose>
        <PopupTextContainer>{props.children}</PopupTextContainer>
      </PopupContent>
    </PopupWrapper>
  );
};

const PopupWrapper = styled.div<PopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: ${props =>
    props.isVisible ? 'visibility 0s linear 0s, opacity 300ms' : 'visibility 0s linear 300ms, opacity 300ms'};
`;

const PopupBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const PopupContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 335px;
  padding: 12px;
  background: ${colors.secondary.slowGrey};
  transform: translate(-50%, -50%);
`;

const PopupClose = styled.div`
  position: absolute;
  top: 20px;
  right: 16px;
  cursor: pointer;
`;

const PopupTextContainer = styled.div`
  background: ${colors.basic.WHITE};
  padding: 8px;

  p:first-child {
    margin-top: 0;
  }
`;
