/* eslint-disable @typescript-eslint/no-explicit-any */

import { CheckboxFields, RangeSliderFields, SelectionButtons } from '../../enums/form-elements.enum';
import { MultipleInputFields, SingleInputFields } from '../../interfaces/Form.interface';

export class UserActionType {
  public static readonly SET_SELECTION_BUTTON_VALUE: string = 'SET_SELECTION_BUTTON_VALUE';
  public static readonly SET_INPUT_FIELD_VALUE: string = 'SET_INPUT_FIELD_VALUE';
  public static readonly SET_MULTIPLE_INPUT_FIELD_VALUE: string = 'SET_MULTIPLE_INPUT_FIELD_VALUE';
  public static readonly SET_SELECTED_VACANCY: string = 'SET_SELECTED_VACANCY';
  public static readonly TOGGLE_CHECKBOX: string = 'TOGGLE_CHECKBOX';
  public static readonly SET_RANGE_SLIDER_VALUES: string = 'SET_RANGE_SLIDER_VALUES';
}

export interface SetSingleInputFieldValuePayload {
  key: SingleInputFields;
  newValue: string;
}

export interface SetMultipleInputFieldValuePayload {
  key: MultipleInputFields;
  values: string[];
}
export interface SetSelectionButtonValuePayload {
  key: SelectionButtons;
  selectedValue: string;
}

export interface ToggleCheckboxPayload {
  key: CheckboxFields;
}

export interface SetRangeSliderValuesPayload {
  key: RangeSliderFields;
  values: number[];
}

export interface SetSelectionButtonValueAction {
  type: typeof UserActionType.SET_SELECTION_BUTTON_VALUE;
  payload: SetSelectionButtonValuePayload;
}

export interface SetSingleInputFieldValueAction {
  type: typeof UserActionType.SET_INPUT_FIELD_VALUE;
  payload: SetSingleInputFieldValuePayload;
}

export interface SetMultipleInputFieldValueAction {
  type: typeof UserActionType.SET_MULTIPLE_INPUT_FIELD_VALUE;
  payload: SetMultipleInputFieldValuePayload;
}

export interface SetSelectedVacancyAction {
  type: typeof UserActionType.SET_SELECTED_VACANCY;
  payload: string;
}

export interface ToggleCheckboxAction {
  type: typeof UserActionType.TOGGLE_CHECKBOX;
  payload: ToggleCheckboxPayload;
}

export interface SetRangeSliderValuesAction {
  type: typeof UserActionType.SET_RANGE_SLIDER_VALUES;
  payload: SetRangeSliderValuesPayload;
}

export type UserActions =
  | SetSelectionButtonValueAction
  | SetSingleInputFieldValueAction
  | SetMultipleInputFieldValueAction
  | ToggleCheckboxAction
  | SetSelectedVacancyAction
  | SetRangeSliderValuesAction;

export const setSelectionButtonValue: (payload: SetSelectionButtonValuePayload) => SetSelectionButtonValueAction = (
  payload: SetSelectionButtonValuePayload,
) => {
  return { type: UserActionType.SET_SELECTION_BUTTON_VALUE, payload: payload };
};

export const setInputFieldValue: (payload: SetSingleInputFieldValuePayload) => SetSingleInputFieldValueAction = (
  payload: SetSingleInputFieldValuePayload,
) => {
  return { type: UserActionType.SET_INPUT_FIELD_VALUE, payload: payload };
};

export const setMultipleInputFieldValue: (
  payload: SetMultipleInputFieldValuePayload,
) => SetMultipleInputFieldValueAction = (payload: SetMultipleInputFieldValuePayload) => {
  return { type: UserActionType.SET_MULTIPLE_INPUT_FIELD_VALUE, payload: payload };
};

export const setSelectedVacancy: (payload: string) => SetSelectedVacancyAction = (payload: string) => {
  return { type: UserActionType.SET_SELECTED_VACANCY, payload: payload };
};

export const toggleCheckbox: (payload: ToggleCheckboxPayload) => ToggleCheckboxAction = (
  payload: ToggleCheckboxPayload,
) => {
  return { type: UserActionType.TOGGLE_CHECKBOX, payload: payload };
};

export const setRangeSliderValues: (payload: SetRangeSliderValuesPayload) => SetRangeSliderValuesAction = (
  payload: SetRangeSliderValuesPayload,
) => {
  return { type: UserActionType.SET_RANGE_SLIDER_VALUES, payload: payload };
};
