import * as React from 'react';
import styled from 'styled-components';
import { NanoLink, NanoLinkType } from './Layout/NanoLink';

interface SocialLinksProps {
  marginTop: number;
}

const SocialLinks: React.FC<SocialLinksProps> = (props: SocialLinksProps) => {
  return (
    <NanoLinkContainer {...props}>
      <NanoLink type={NanoLinkType.HYPERLINK} text={'LinkedIn'} target={'https://de.linkedin.com/company/nanogiants'} />
      <NanoLink
        type={NanoLinkType.HYPERLINK}
        text={'Instagram'}
        target={'https://www.instagram.com/nanogiants_gmbh/'}
      />
      <NanoLink type={NanoLinkType.HYPERLINK} text={'Medium'} target={'https://medium.com/nanogiants'} />
      <NanoLink type={NanoLinkType.HYPERLINK} text={'Karriereseite'} target={'https://nanogiants.de/people/'} />
    </NanoLinkContainer>
  );
};

const NanoLinkContainer = styled.div`
  display: flex;
  min-width: 300px;
  justify-content: space-evenly;
  margin-top: ${(props: SocialLinksProps) => (props.marginTop ? props.marginTop : 0)}px;
`;

export default SocialLinks;
