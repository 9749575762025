import axios from 'axios';
import { NextLevelData } from '../interfaces/Vacancy.interface';

export class NextLevelAPI {
  public static instance: NextLevelAPI;

  private constructor() {
    axios.defaults.baseURL = ' http://localhost:3000';
  }

  public static getInstance(): NextLevelAPI {
    if (!this.instance) {
      this.instance = new NextLevelAPI();
    }
    return this.instance;
  }

  public async getVacancys(): Promise<NextLevelData> {
    //return (await axios.get('./nextLevelData.json')).data;
    return {
      vacancies: [
        {
          key: 'scrum-master',
          vacancyName: 'Scrum Master:in',
          vacancyText:
            'Als Scrum Master bist du ein wichtiges Bindeglied in einem komplexen Umfeld. Du bist nicht nur für die Weiterentwicklung deiner Scrum-Teams verantwortlich, sondern auch für die Entwicklung unserer Kunden. Unser Credo lautet People First. Darum stehst du als Vorbild im Sinne eines “True Leaders”. Zusätzlich spielst du bei der strategischen Unternehmensentwicklung eine wichtige Rolle. ',
        },
        {
          key: 'fullstack-dev',
          vacancyName: 'Full Stack Developer:in',
          vacancyText:
            'Ob Frontend oder Backend oder ein komplexes Web-Portal: Als Softwarentwickler:in treibst du gemeinsam mit deinem Scrum Team die Umsetzung digitaler Projekte voran, um unsere gemeinsame Mission weiter zu verfolgen. Wir garantieren dir einen breiten Technologie-Stack, der dich befähigt, immer weiter zu wachsen. Unseren aktuellen Tech-Stack findest du hier: ',
        },
        {
          key: 'uxui-designer',
          vacancyName: 'UI/UX Designer:in',
          vacancyText:
            'Du bist bei uns richtig, wenn der Nutzer für dich immer im Mittelpunkt steht. Dein Ziel ist es, ihm ein echtes Wow-Erlebnis zu vermitteln.  Zusammen mit deinem Scrum Team begleitest du unsere Kunden langfristig von der ersten Idee bis zur Umsetzung. Durch gezielte Weiterbildung  baust du dein Know-how aus und bist in der Lage, neue Technologien und Methoden auszuprobieren. ',
        },
        {
          key: 'ios-android-dev',
          vacancyName: 'iOS & Android Developer:in',
          vacancyText:
            'Als iOS/Android Developer:in treibst du gemeinsam mit deinem Scrum Team die Umsetzung digitaler Projekte voran, um unsere gemeinsame Mission weiter zu verfolgen. Gemeinsam mit deinem Team bestimmt du die Technologische Ausrichtung und durch gezielte Weiterbildung befähigen wir dich, dein Know-how immer weiter auszubauen. Unseren aktuellen Tech-Stack findest du hier:',
        },
        {
          key: 'insight-sales-giant',
          vacancyName: 'Insight Sales Giant ',
          vacancyText:
            'Als Sales Giant bist auf der Jagd nach Neukunden. Mit deinen Präsentations Skills begeisterst du neue Kunden von den Fähigkeiten der NanoGiants bis hin zur Projektbeauftragung. In Zusammenarbeit mit unseren Scrum Mastern und Product Ownern unterstützt du das Up- und Crosssellgeschäft. Dabei verlierst du unsere Core Values nie aus dem Blick.',
        },
      ],
    };
  }
}
