import { appReducer } from './app.reducer';
import { combineReducers } from 'redux';
import { userReducer } from './user.reducer';

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
});

export default rootReducer;
