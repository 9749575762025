import React, { FunctionComponent } from 'react';

const NeinToFive: FunctionComponent = () => (
  <svg viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M25.9518 56.4373H56.4412V19.1168L35.2059 0.735107L13.9706 19.1168V25.2441"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9706 19.6738H56.4412"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.2058 15.2177C37.0576 15.2177 38.5588 13.7214 38.5588 11.8756C38.5588 10.0298 37.0576 8.53345 35.2058 8.53345C33.3541 8.53345 31.8529 10.0298 31.8529 11.8756C31.8529 13.7214 33.3541 15.2177 35.2058 15.2177Z"
        stroke="white"
        strokeWidth="1.11765"
        strokeMiterlimit="10"
      />
      <path
        d="M26 28.9998H44V55.9998"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 28.0292V25.2441H19.5588V28.0292"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8529 40.8406L7.82349 35.8274"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5589 37.4985L16.2059 40.8407"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5294 44.7399C15.7639 44.7399 16.7647 43.7423 16.7647 42.5118C16.7647 41.2812 15.7639 40.2837 14.5294 40.2837C13.2948 40.2837 12.2941 41.2812 12.2941 42.5118C12.2941 43.7423 13.2948 44.7399 14.5294 44.7399Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2354 12.9895V2.96313H27.9412V6.86228"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M26.8235 28.0349H2.23529C1.00029 28.0349 0 29.032 0 30.263V54.7719C0 56.003 1.00029 57 2.23529 57H26.8235C28.0585 57 29.0588 56.003 29.0588 54.7719V30.263C29.0588 29.032 28.0585 28.0349 26.8235 28.0349ZM14.5294 54.7664C7.73971 54.7664 2.23529 49.2797 2.23529 42.5119C2.23529 35.7441 7.73971 30.2574 14.5294 30.2574C21.3191 30.2574 26.8235 35.7441 26.8235 42.5119C26.8235 49.2797 21.3191 54.7664 14.5294 54.7664Z"
        fill="#D90855"
      />
    </g>
  </svg>
);

export default NeinToFive;
