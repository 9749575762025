import React from 'react';
import styled from 'styled-components';
import Typography from '../../../styles/Typography';

export interface MissionListProps {
  companyMissions?: CompanyMissionItem[];
}

export interface CompanyMissionItem {
  icon: JSX.Element;
  title: string;
  text: string;
}

export const CompanyMissionList: React.FC<MissionListProps> = (props: MissionListProps) => {
  return (
    <>
      {props.companyMissions?.length ? (
        <MissionGoalWrapper>
          {props.companyMissions.map((item: CompanyMissionItem) => {
            return (
              <MissionItem key={item.title}>
                <MissionIconContainer>{item.icon}</MissionIconContainer>
                <MissionTextContainer>
                  <Typography.NanoHeadingSGSB15 fontSize={28}>{item.title}</Typography.NanoHeadingSGSB15>
                  <Typography.NanoText marginTop={11}>{item.text}</Typography.NanoText>
                </MissionTextContainer>
              </MissionItem>
            );
          })}
        </MissionGoalWrapper>
      ) : null}
    </>
  );
};

const MissionGoalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MissionItem = styled.div`
  display: flex;
  margin-top: 40px;
`;

const MissionIconContainer = styled.div`
  width: 44px;
`;

const MissionTextContainer = styled.div`
  flex: 1;
  margin-left: 16px;

  display: flex;
  flex-direction: column;
`;
