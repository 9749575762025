import React, { FunctionComponent } from 'react';

const ListOne: FunctionComponent = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19" stroke="white" strokeWidth="2" />
    <path
      d="M24.436 29V25.528H21.888V8.924H19.06C18.64 10.772 17.8 11.668 16.176 11.92V14.58H18.332V25.528H15.672V29H24.436Z"
      fill="white"
    />
  </svg>
);

export default ListOne;
