import React from 'react';
import styled from 'styled-components';
import { PersonEnum } from '../enums/person.enum';
import { Person } from '../interfaces/Person.interface';
import { colors, fonts } from '../styles/Theme';

import MaritAvatar from '../assets/images/avatar-marit.png';
import MichiAvatar from '../assets/images/avatar-michi.png';
import JanAvatar from '../assets/images/avatar-jan.png';
import ConstantinAvatar from '../assets/images/avatar-constantin.png';
import NadjaAvatar from '../assets/images/avatar-nadja.png';
import DennisAvatar from '../assets/images/avatar-dennis.png';

export interface AvatarProps {
  person: PersonEnum;
  disableName?: boolean;
}

const PersonData: Person[] = [
  {
    key: PersonEnum.MARIT,
    name: 'Marit Funke',
    imageSrc: MaritAvatar,
  },
  {
    key: PersonEnum.MICHELE,
    name: 'Michèle Czarnecki',
    imageSrc: MichiAvatar,
  },
  {
    key: PersonEnum.JAN,
    name: 'Jan-Erik Lorfeo',
    imageSrc: JanAvatar,
  },
  {
    key: PersonEnum.CONSTANTIN,
    name: 'Constantin Dießenbacher',
    imageSrc: ConstantinAvatar,
  },
  {
    key: PersonEnum.NADJA,
    name: 'Nadja Niesner',
    imageSrc: NadjaAvatar,
  },
  {
    key: PersonEnum.DENNIS,
    name: 'Dennis Matthias Scherf',
    imageSrc: DennisAvatar,
  },
];

export const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  const avatarData = PersonData.find(person => person.key === props.person) as Person;
  return (
    <AvatarWrapper>
      <AvatarImage src={avatarData?.imageSrc} alt={`Avatar ${avatarData?.name}`} />
      {props.disableName ? null : <AvatarName>{avatarData?.name}</AvatarName>}
    </AvatarWrapper>
  );
};

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AvatarImage = styled.img`
  margin: 0 auto;
  border-radius: 100%;
  border: 3px solid ${colors.basic.WHITE};
  width: 80px;
`;

const AvatarName = styled.strong`
  padding-top: 5px;
  font-family: ${fonts.SharpGroteskSemiBold19};
  font-size: 16px;
  line-height: 26px;
  color: ${colors.basic.WHITE};
  text-align: center;
`;
