import React from 'react';
import Typography, { TextProps } from '../../styles/Typography';

export interface NanoLinkProps extends TextProps {
  type: NanoLinkType;
  text: string;
  target: string;
}

export enum NanoLinkType {
  MAIL = 'mail',
  HYPERLINK = 'hyperlink',
  PHONE = 'phone',
}

export const NanoLink: React.FC<NanoLinkProps> = (props: NanoLinkProps) => {
  const getLink = () => {
    if (props.type === NanoLinkType.MAIL || props.type === NanoLinkType.HYPERLINK) {
      return (
        <Typography.NanoLink {...props} href={props.target}>
          {props.text}
        </Typography.NanoLink>
      );
    }
    return <Typography.NanoLink {...props}>{props.text}</Typography.NanoLink>;
  };

  return getLink();
};
