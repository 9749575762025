export enum NavigationRoute {
  START = '/start',
  VACANCY_DETAIL = '/vacancy-detail',
  COMPANY_MISSION = '/company-mission',
  SUCCESS = '/success',
  ROOT = '/',
  QUIZ_EXPERIENCE = '/quiz/experience',
  QUIZ_MORE_ABOUT_YOURSELF = '/quiz/more-about-yourself',
  QUIZ_YOUR_CONTACT_DETAILS = '/quiz/your-contact-details',
  DATA_PROTECTION = '/data-protection',
}
