import React, { FunctionComponent } from 'react';

const AvatarSuccessIcon: FunctionComponent = () => (
  <svg width="220" height="164" viewBox="0 0 227 170" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M92.485 166.233L95.8392 165.195C97.1363 164.822 97.5847 163.89 97.5847 163.89L103.997 163.87L103.556 170H88.804C89.6826 168.191 90.5641 166.827 92.485 166.233Z"
      fill="#C6004C"
    />
    <path d="M104.686 157.72L103.997 163.87L97.5708 163.89L98.2248 157.765L104.686 157.72Z" fill="white" />
    <path
      d="M122.494 166.233L119.14 165.195C117.843 164.822 117.789 163.89 117.789 163.89L111.066 163.915L110.749 170H125.698C124.819 168.191 124.415 166.828 122.494 166.233Z"
      fill="#C6004C"
    />
    <path d="M111.311 157.886L111.065 163.915L117.789 163.89L118.128 158.786L111.311 157.886Z" fill="white" />
    <path
      d="M96.8751 84.125H97.7188H124.438L120.101 160.486H111.044L110.078 98.6173L104.892 160.486H96.0125L96.8751 84.125Z"
      fill="#43447C"
    />
    <ellipse
      cx="16.2062"
      cy="16.6263"
      rx="15.1875"
      ry="3.28125"
      transform="rotate(7.69896 16.2062 16.6263)"
      fill="#C6004C"
    />
    <ellipse
      cx="15.5839"
      cy="16.062"
      rx="15.1875"
      ry="3.28125"
      transform="rotate(7.69896 15.5839 16.062)"
      fill="#43447C"
    />
    <path d="M37.9888 19.3568L32.6943 18.8966L32.6456 19.457L37.9401 19.9172L37.9888 19.3568Z" fill="white" />
    <path d="M40.7509 17.2056L32.3528 16.4756L32.3041 17.036L40.7022 17.766L40.7509 17.2056Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.6251 39.5L66.6428 39.423L56.5137 21.8687C55.9125 20.8271 54.804 20.1875 53.6045 20.1875H51.2497C49.5282 20.1875 48.1294 21.5841 48.1294 23.3064C48.1294 25.0316 49.5282 26.4282 51.2497 26.4282H53.4462L58.5049 42.005C59.502 45.074 61.9067 47.4799 64.9742 48.4745L81.3761 53.8086L76.6251 39.5Z"
      fill="#C6004C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.75 39.5H97.0625H76.625L81.3764 53.809L91.4792 57.0922L97.0625 84.125H124.625L124.474 59.6947L127.327 60.9651L137.538 49.6192L126.603 41.6709C124.597 40.2098 122.231 39.5 119.75 39.5Z"
      fill="white"
    />
    <path
      d="M114.405 57.212L107.565 72.4703C107.182 73.3245 107.564 74.3254 108.419 74.7061L128.84 83.8071C129.694 84.1877 130.697 83.8039 131.079 82.9497L137.919 67.6914C138.302 66.8373 137.92 65.8363 137.066 65.4556L116.645 56.3546C115.791 55.974 114.788 56.3578 114.405 57.212Z"
      fill="#100F39"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.064 68.054C118.958 67.8797 118.89 67.6843 118.867 67.4812C118.843 67.2782 118.864 67.0724 118.928 66.878C119.11 66.9338 119.279 67.0259 119.424 67.1488C119.57 67.2717 119.689 67.4228 119.774 67.5931C119.874 67.754 119.932 67.9381 119.941 68.1279C119.95 68.3176 119.911 68.5067 119.827 68.6771C119.663 68.6317 119.511 68.5529 119.38 68.4456C119.248 68.3384 119.141 68.205 119.064 68.054Z"
      fill="#43447C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.6 67.8984C121.895 67.7126 120.795 68.1895 120.52 68.8372C120.27 69.4259 120.594 70.4068 121.124 70.8646C121.178 70.9171 121.234 70.9706 121.292 71.025L121.292 71.025C121.684 71.3983 122.119 71.8123 122.378 72.2283L122.378 72.2271C122.66 72.76 123.113 73.1979 123.7 73.4473C125.109 74.0457 126.759 73.3331 127.387 71.8558C127.769 70.956 127.683 69.98 127.24 69.2193C126.837 69.7651 126.099 69.9846 125.447 69.7078C124.74 69.4072 124.382 68.6272 124.593 67.9071C124.454 67.9247 124.316 67.9531 124.181 67.9918C123.699 68.0634 123.158 68.062 122.659 67.9159C122.644 67.9109 122.629 67.9063 122.613 67.902L122.598 67.8974L122.6 67.8984Z"
      fill="#43447C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.539 49.618L127.328 60.9668L136.724 65.1507L125.219 77.9313H122.486C120.765 77.9313 119.366 79.3309 119.366 81.0532C119.366 82.7784 120.765 84.175 122.486 84.175H124.835C125.316 84.175 125.779 84.0224 126.166 83.7407L149.709 66.6177C152.478 64.605 152.478 60.4768 149.709 58.4641L137.539 49.618Z"
      fill="#C6004C"
    />
    <path
      d="M109.625 30.125C107.72 30.125 106.165 31.6196 106.067 33.5H106.062V35.5625C106.062 35.9767 105.727 36.3125 105.312 36.3125H104.844C103.964 36.3125 103.25 37.026 103.25 37.9062C103.25 38.7865 103.964 39.5 104.844 39.5H113.656C114.536 39.5 115.25 38.7865 115.25 37.9062C115.25 37.026 114.536 36.3125 113.656 36.3125H113.375V33.125C113.375 31.4681 112.032 30.125 110.375 30.125H109.625Z"
      fill="#43447C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.085 34.8116C109.352 35.2728 109.161 35.8632 108.675 36.0815L108.104 36.3382C107.55 36.5868 107.333 37.2591 107.636 37.7844L107.689 37.8753C108.014 38.438 108.712 38.6636 109.305 38.3971L109.742 39.154C110.738 40.8769 113.373 40.1712 113.373 38.1818V32.8853L109.085 34.8116Z"
      fill="#C6004C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.375 34.0625C114.1 34.0625 114.687 33.4749 114.687 32.75C114.687 32.0251 114.1 31.4375 113.375 31.4375C112.65 31.4375 112.062 32.0251 112.062 32.75C112.062 32.8137 112.067 32.8763 112.076 32.9375H107V35.1875H110.375C111.344 35.1875 112.17 34.575 112.487 33.7162C112.72 33.9312 113.032 34.0625 113.375 34.0625Z"
      fill="#43447C"
    />
    <path
      d="M157.92 166.233L161.274 165.195C162.572 164.822 163.02 163.89 163.02 163.89L169.349 163.915L168.284 170H153.532C153.967 169.105 154.58 168.309 155.335 167.661C156.09 167.013 156.97 166.527 157.92 166.233Z"
      fill="#D90855"
    />
    <path d="M170.876 157.838L169.349 163.915L163.019 163.889L164.255 157.85L170.876 157.838Z" fill="white" />
    <path
      d="M198.891 166.233L195.537 165.195C194.24 164.822 194.186 163.89 194.186 163.89L187.462 163.915L187.146 170H202.095C201.216 168.191 200.812 166.828 198.891 166.233Z"
      fill="#C6004C"
    />
    <path d="M187.708 157.886L187.462 163.915L194.186 163.89L194.525 158.787L187.708 157.886Z" fill="white" />
    <path
      d="M173.063 84.125L178.5 84.1282H200.625L196.498 160.486H187.441L185.813 98.5625L172.556 160.486H163.677L173.063 84.125Z"
      fill="#43447C"
    />
    <path
      d="M159.491 57.6351L161.938 48.802C162.679 46.1278 164.277 43.77 166.487 42.0899C168.698 40.4098 171.399 39.5 174.176 39.5H202.721C204.419 39.5 206.1 39.8402 207.664 40.5005C209.228 41.1608 210.643 42.1278 211.827 43.3442C213.01 44.5607 213.937 46.0018 214.553 47.5825C215.169 49.1632 215.462 50.8513 215.413 52.5469L215.135 62.3211L204.007 62.6771L204.006 57.9096L200.625 84.125H173.062L172.81 61.4303L159.491 57.6351Z"
      fill="white"
    />
    <path
      d="M204.007 62.6771L203.849 76.1407L188.176 77.6027C187.657 77.6344 187.161 77.8296 186.76 78.1601L184.622 79.9211C184.355 80.1404 184.138 80.4132 183.984 80.7217C183.83 81.0303 183.743 81.3677 183.728 81.7122C183.713 82.0567 183.77 82.4004 183.897 82.7212C184.023 83.042 184.216 83.3327 184.462 83.5743C184.896 84.0005 185.473 84.251 186.081 84.2775C186.689 84.304 187.285 84.1047 187.755 83.7179L190.179 81.7214L209.179 83.9537C209.781 84.0244 210.391 83.9714 210.972 83.7978C211.553 83.6243 212.092 83.334 212.556 82.9447C213.02 82.5553 213.4 82.0753 213.672 81.534C213.943 80.9927 214.101 80.4016 214.136 79.797L215.135 62.3212L204.007 62.6771Z"
      fill="#C6004C"
    />
    <path d="M159.492 57.635L155.265 72.8969L166.203 74.0654L172.81 61.4303L159.492 57.635Z" fill="#C6004C" />
    <path
      d="M151.231 84.1356H179.785V86.5626H153.297C152.749 86.5626 152.224 86.3452 151.836 85.9583C151.449 85.5714 151.231 85.0466 151.231 84.4993V84.1355L151.231 84.1356Z"
      fill="#100F39"
    />
    <path
      d="M179.741 84.1356H189.535V84.4994C189.535 85.0467 189.317 85.5715 188.93 85.9584C188.542 86.3453 188.017 86.5627 187.469 86.5627H179.741V84.1356Z"
      fill="#E7799A"
    />
    <path
      d="M179.785 84.1197H151.231L147.944 64.6687C147.9 64.4075 147.913 64.1399 147.983 63.8845C148.053 63.6291 148.178 63.392 148.35 63.1897C148.521 62.9875 148.734 62.8249 148.974 62.7134C149.215 62.6019 149.477 62.5442 149.742 62.5442H174.598C175.029 62.5442 175.446 62.6967 175.775 62.9746C176.104 63.2525 176.324 63.6378 176.395 64.0623L179.785 84.1197Z"
      fill="#43447C"
    />
    <path
      d="M163.25 73.7924L163.582 75.6667H162.612L162.931 73.8052L161.489 75.0165L161.004 74.1878L162.791 73.5375L161.017 72.8999L161.489 72.0711L162.931 73.2697L162.612 71.4082H163.582L163.238 73.2951L164.693 72.0585L165.178 72.8871L163.378 73.5376L165.178 74.1879L164.705 75.0165L163.25 73.7924Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M188.875 31.1654C188.338 30.8615 187.714 30.6875 187.048 30.6875H186.264C184.254 30.6875 182.625 32.2744 182.625 34.2328V34.7157H186.552V38.75H188.109C189.533 38.75 190.687 37.6274 190.687 36.2407V34.2328C190.687 33.3813 190.38 32.6 189.866 31.9889C189.401 31.9256 189.019 31.5993 188.875 31.1654Z"
      fill="#43447C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M188.698 35.6085H188.57V34.4375H183.938L185.415 38.9507C185.983 40.691 188.57 40.2844 188.57 38.4527V37.5907C189.081 37.55 189.496 37.1576 189.55 36.6433L189.558 36.5559C189.611 36.0497 189.21 35.6085 188.698 35.6085Z"
      fill="#C6004C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M183.562 32.1875V34.6881H187.281C187.245 35.1581 187.596 35.5625 188.045 35.5625H189.562V34.6881V32.1875H183.562Z"
      fill="#43447C"
    />
  </svg>
);

export default AvatarSuccessIcon;
