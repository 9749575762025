import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface ScrollToTopProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}
export const ScrollToTop: React.FC<ScrollToTopProps> = (props: ScrollToTopProps) => {
  // inspired by https://stackoverflow.com/questions/48219432/react-router-typescript-errors-on-withrouter-after-updating-version

  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <Fragment>{props.children}</Fragment>;
};

export default ScrollToTop;
