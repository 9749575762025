import React from 'react';

import styled from 'styled-components';
import { FlexPosition } from '../enums/flex-position.enum';
import { TextAlign } from '../enums/text-align.enum';
import { colors, fonts } from '../styles/Theme';

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

type OnClickFunction = () => void;
export interface ButtonProps {
  label: string;
  isBlock?: boolean;
  isDisabled?: boolean;
  marginTop?: number;
  marginBottom?: number;
  minWidth?: number;
  type?: ButtonType;
  additionalButtonContent?: JSX.Element;
  textAlign?: TextAlign;
  alignSelf?: FlexPosition;
  onClick?: OnClickFunction;
}

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const buttonContent = (
    <>
      {props.label}
      {props.additionalButtonContent ? props.additionalButtonContent : null}
    </>
  );
  const primaryButton = <PrimaryButton {...props}>{buttonContent}</PrimaryButton>;
  const secondaryButton = <SecondaryButton {...props}>{buttonContent}</SecondaryButton>;

  return props.type === ButtonType.SECONDARY ? secondaryButton : primaryButton;
};

const CoreButton = styled.span<ButtonProps>`
  display: ${props => (props.isBlock ? 'block' : 'inline-block')};
  padding: 15px 35px;
  border-radius: 5px;
  min-width: ${props => (props.minWidth ? props.minWidth - 70 + 'px' : 0 + 'px')};
  color: ${colors.basic.WHITE};
  font-family: ${fonts.SharpGroteskSemiBold19};
  text-align: ${(props: ButtonProps) => props.textAlign ?? TextAlign.LEFT};
  font-size: 20px;
  line-height: 30px;
  white-space: pre-wrap;
  margin-top: ${props => props.marginTop}px;
  margin-bottom: ${props => props.marginBottom}px;
  cursor: pointer;
  transition: 0.2s background ease-in, 0.2s color ease-in;
  align-self: ${(props: ButtonProps) => props.alignSelf ?? null};
`;

const PrimaryButton = styled(CoreButton)<ButtonProps>`
  background-color: ${props => (props.isDisabled ? colors.primary.giantRedInactive : colors.primary.giantRed)};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${props => (props.isDisabled ? null : colors.basic.WHITE)};
      color: ${props => (props.isDisabled ? null : colors.primary.giantRed)};
    }
  }

  &:active {
    background-color: ${props => (props.isDisabled ? null : colors.secondary.slowGrey)};
    color: ${props => (props.isDisabled ? null : colors.primary.titanRed)};
  }
`;

const SecondaryButton = styled(CoreButton)<ButtonProps>`
  background-color: ${props => (props.isDisabled ? colors.secondary.white50 : colors.secondary.microBlue)};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${props => (props.isDisabled ? null : colors.basic.WHITE)};
      color: ${props => (props.isDisabled ? null : colors.secondary.microBlue)};
    }
  }

  &:active {
    background-color: ${props => (props.isDisabled ? null : colors.secondary.slowGrey)};
    color: ${props => (props.isDisabled ? null : colors.secondary.microBlue)};
  }
`;

export default Button;
