import { createGlobalStyle } from 'styled-components';

import CinderBlock100Woff2 from '../assets/fonts/CinderBlock/cinderblock-100.woff2';
import CinderBlock100Woff from '../assets/fonts/CinderBlock/cinderblock-100.woff';

import CinderBlock400Woff2 from '../assets/fonts/CinderBlock/cinderblock-400.woff2';
import CinderBlock400Woff from '../assets/fonts/CinderBlock/cinderblock-400.woff';

import SharpGrotesk19Woff2 from '../assets/fonts/SharpGrotesk/SharpGroteskBook19.woff2';
import SharpGrotesk19Woff from '../assets/fonts/SharpGrotesk/SharpGroteskBook19.woff';

import SharpGroteskSemiBold15Woff2 from '../assets/fonts/SharpGrotesk/SharpGroteskSmBold15.woff2';
import SharpGroteskSemiBold15Woff from '../assets/fonts/SharpGrotesk/SharpGroteskSmBold15.woff';

import SharpGroteskSemiBold19Woff2 from '../assets/fonts/SharpGrotesk/SharpGroteskSmBold19.woff2';
import SharpGroteskSemiBold19Woff from '../assets/fonts/SharpGrotesk/SharpGroteskSmBold19.woff';

import { fonts } from './Theme';

export default createGlobalStyle`
    @font-face {
        font-family: ${fonts.CinderBlock100};
        src: local(${fonts.CinderBlock100}), local(${fonts.CinderBlock100}),
        url(${CinderBlock100Woff2}) format('woff2'),
        url(${CinderBlock100Woff}) format('woff');
    }

    @font-face {
        font-family: ${fonts.CinderBlock400};
        src: local(${fonts.CinderBlock400}), local(${fonts.CinderBlock400}),
        url(${CinderBlock400Woff2}) format('woff2'),
        url(${CinderBlock400Woff}) format('woff');
    }

    @font-face {
        font-family: ${fonts.SharpGrotesk19};
        src: local(${fonts.SharpGrotesk19}), local(${fonts.SharpGrotesk19}),
        url(${SharpGrotesk19Woff2}) format('woff2'),
        url(${SharpGrotesk19Woff}) format('woff');
    }

    @font-face {
        font-family: ${fonts.SharpGroteskSemiBold15};
        src: local(${fonts.SharpGroteskSemiBold15}), local(${fonts.SharpGroteskSemiBold15}),
        url(${SharpGroteskSemiBold15Woff2}) format('woff2'),
        url(${SharpGroteskSemiBold15Woff}) format('woff');
    }

    @font-face {
        font-family: ${fonts.SharpGroteskSemiBold19};
        src: local(${fonts.SharpGroteskSemiBold19}), local(${fonts.SharpGroteskSemiBold19}),
        url(${SharpGroteskSemiBold19Woff2}) format('woff2'),
        url(${SharpGroteskSemiBold19Woff}) format('woff');
    }
`;
