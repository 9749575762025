import styled from 'styled-components';

import { FlexPosition } from '../enums/flex-position.enum';

export interface SceneWrapperProps {
  alignItems?: FlexPosition;
}

export interface WrapperProps {
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
}

export default {
  DefaultSceneWrapper: styled.div<SceneWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: ${(props: SceneWrapperProps) => (props.alignItems ? props.alignItems : FlexPosition.CENTER)};
    padding: 15px;
  `,
  TwoColumnGrid: styled.div`
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 1 * 16px) / 2));
    column-gap: 16px;
    row-gap: 16px;
  `,
  ThreeColumnGrid: styled.div`
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 2 * 16px) / 3));
    column-gap: 16px;
    row-gap: 16px;
  `,
  Wrapper: styled.div<WrapperProps>`
    position: relative;
    width: 100%;
    margin-left: ${props => props.marginLeft ?? 0}px;
    margin-top: ${props => props.marginTop ?? 26}px;
    margin-right: ${props => props.marginRight ?? 0}px;
    margin-bottom: ${props => props.marginBottom ?? 32}px;
  `,
};
