import {
  CheckboxFields,
  CombinedMultipleInputFields,
  CombinedSingleInputFields,
  RangeSliderFields,
  SelectionButtons,
} from '../enums/form-elements.enum';
import { MultipleInputField, SingleInputField, SelectionButton, CheckboxField } from '../interfaces/Form.interface';
import { Store } from '../interfaces/Store.interface';

export const defaultState: Store = {
  app: {
    count: 0,
  },
  user: {
    selectedVacancy: '',
    selectionButtons: Object.keys(SelectionButtons).map(
      (key: string) =>
        <SelectionButton>{
          key,
          value: '',
        },
    ),
    singleInputFields: Object.keys(CombinedSingleInputFields).map(
      (key: string) =>
        <SingleInputField>{
          key,
          value: '',
        },
    ),
    multipleInputFields: Object.keys(CombinedMultipleInputFields).map(
      (key: string) =>
        <MultipleInputField>{
          key,
          values: [''],
        },
    ),
    checkboxFields: Object.keys(CheckboxFields).map(
      (key: string) =>
        <CheckboxField>{
          key,
          checked: false,
        },
    ),
    rangeSliderFields: [
      {
        key: RangeSliderFields.YOUR_SALARY_EXPECTATIONS,
        values: [10000, 150000],
        step: 2000,
        min: 10000,
        max: 150000,
      },
    ],
  },
};
