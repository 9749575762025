import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NextLevelAPI } from '../../api/api';
import Button, { ButtonType } from '../../components/Button';
import { Emoji } from '../../components/Emoji';
import { DefaultLayout } from '../../components/Layout/DefaultLayout';
import { FlexPosition } from '../../enums/flex-position.enum';
import { NavigationRoute } from '../../enums/navigation.enum';
import { TextAlign } from '../../enums/text-align.enum';
import { Vacancy, NextLevelData } from '../../interfaces/Vacancy.interface';
import Layout from '../../styles/Layout';
import Typography from '../../styles/Typography';
import { VacancyHeader } from './components/VacancyHeader';
import Vimeo from '@u-wave/react-vimeo';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export const VacancyDetail: React.FC = () => {
  const params: { key: string } = useParams();
  const { trackEvent } = useMatomo();

  const vacancyKey: string = params.key;

  const api: NextLevelAPI = NextLevelAPI.getInstance();

  const [availablePositions, setAvailablePositions] = useState<Vacancy[]>([]);
  const [currentVacancy, setCurrentVacancy] = useState<Vacancy>();

  useEffect(() => {
    async function selectVacancy() {
      if (!availablePositions.length) {
        await fetchData();
      }
      if (availablePositions.length) {
        const selectedVacancy: Vacancy | undefined = availablePositions.find(job => job.key === vacancyKey);
        if (selectedVacancy) {
          setCurrentVacancy(selectedVacancy);
        }
      }
    }
    async function fetchData() {
      const vacancyData: NextLevelData = await api.getVacancys();
      setAvailablePositions(vacancyData.vacancies);
    }

    selectVacancy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancyKey, availablePositions]);

  // const benefitListData: BenefitItem[] = [
  //   {
  //     title: '...und das Beste?',
  //     text:
  //       'Du brauchst keine Bewerbung schreiben und auch kein ausführliches Anschreiben. Bei uns kannst du dich innerhalb einer Minute mit ein paar Klicks bewerben.',
  //   },
  //   {
  //     title: '...und was ist mit Corona?',
  //     text:
  //       'Einstellungsstopp, Kurzarbeit und keine Möglichkeiten im Home Office zu arbeiten? Das alles kennen wir nicht. Trotz der Covid-19-Pandemie konnten wir in den letzten Monaten 6 neue NanoGiants einstellen und das alles entspannt remote.',
  //   },
  //   {
  //     title: '...und was machen wir?',
  //     text:
  //       'Wir bauen maßgeschneiderte Softwareprodukte, die ohne Umwege zu marktfähigen Lösungen führen. Wir glauben an eine Welt, in der Technologie jedem Menschen hilft zu wachsen. Aber die wichtigste Voraussetzung für technologischen Erfolg ist und bleibt der Mensch.',
  //   },
  //   {
  //     title: '...und wie sind wir so?',
  //     text:
  //       'Unsere 6 Core Values Exzellenz, Mut, Entschlossenheit, Authentizität, Aufgeschlossenheit und Vertrauen sind unsere Handlungsprinzipien. Sie ergeben unsere Leitplanken für Entscheidungen, für unser Handeln und unsere Zusammenarbeit mit unseren Kunden. Sie begleiten uns jeden Tag und durch gemeinsame Core Value Action Days und Core Value basierten Feedback Gespräche werden sie jeden Tag lebendig.',
  //   },
  // ];

  const rocketEmoji = <Emoji label="rocket-emoji" symbol="🚀" />;

  const handleOnClick = () => {
    // Track click on button
    trackEvent({
      category: 'sample-page',
      action: 'click-event',
      name: 'test', // optional
      value: 123, // optional, numerical value
      documentTitle: 'Page title', // optional
      href: 'https://LINK.TO.PAGE', // optional
      customDimensions: [
        {
          id: 1,
          value: 'loggedIn',
        },
      ], // optional
    });
  };

  const VacancyDetailContent = (
    <Layout.DefaultSceneWrapper alignItems={FlexPosition.FLEX_START}>
      <VacancyHeader vacancy={currentVacancy} />
      {/* <TopImageContainer>
        <AvatarMeditating />
      </TopImageContainer> */}
      {/* <BenefitList benefitList={benefitListData} /> */}

      <VideoContainer>
        <Vimeo video="https://player.vimeo.com/video/433648276" showTitle={false} showByline={false} volume={1} />
      </VideoContainer>

      {/* <BottomImageContainer>
        <AvatarSitLaptop />
      </BottomImageContainer> */}

      <Typography.NanoHeadingSGSB15 fontSize={28} alignSelf={FlexPosition.CENTER} marginTop={50}>
        Klingt Interessant?
      </Typography.NanoHeadingSGSB15>
      <ButtonLink to={NavigationRoute.QUIZ_EXPERIENCE}>
        <Button
          textAlign={TextAlign.CENTER}
          label="Direkt Bewerben "
          minWidth={304}
          marginTop={24}
          additionalButtonContent={rocketEmoji}
          alignSelf={FlexPosition.CENTER}
        />
      </ButtonLink>
      <ButtonLink to={NavigationRoute.COMPANY_MISSION}>
        <Button
          onClick={handleOnClick}
          textAlign={TextAlign.CENTER}
          label="Mehr zu den NanoGiants"
          minWidth={304}
          marginTop={20}
          type={ButtonType.SECONDARY}
          alignSelf={FlexPosition.CENTER}
        ></Button>
      </ButtonLink>
    </Layout.DefaultSceneWrapper>
  );

  return <DefaultLayout content={VacancyDetailContent} showBackButton={true} />;
};

const VideoContainer = styled.div`
  margin-top: 30px;
  width: 100%;

  iframe {
    width: 100%;
  }
`;

// const TopImageContainer = styled.div`
//   flex: 1;
//   width: 134px;
//   align-self: center;
//   margin-top: 23px;
// `;

// const BottomImageContainer = styled.div`
//   flex: 1;
//   width: 116px;
//   align-self: center;
//   margin-top: 32px;
// `;

const ButtonLink = styled(Link)`
  flex: 1;
  align-self: center;
`;
