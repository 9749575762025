const colors = {
  primary: {
    nanoBlue: '#19194A',
    nanoBlueLight: '#2E2E63',
    giantRed: '#D90855',
    giantRedInactive: '#E7799A',
    titanRed: '#C6004C',
  },
  secondary: {
    slowGrey: '#DDDDDD',
    white50: '#8C8CA4',
    microBlue: '#43447C',
  },
  basic: {
    BLACK: '#000000',
    WHITE: '#ffffff',
  },
};

const fonts = {
  CinderBlock100: 'CinderBlock100',
  CinderBlock400: 'CinderBlock400',
  SharpGrotesk19: 'SharpGrotesk',
  SharpGroteskSemiBold15: 'SharpGroteskSm15',
  SharpGroteskSemiBold19: 'SharpGroteskSm19',
};

export { colors, fonts };
