import en from './langs/en.json';
import de from './langs/de.json';
import { initReactI18next } from 'react-i18next';
import { Language } from '../enums/language.enum';
import i18n from 'i18next';

i18n.use(initReactI18next).init({
  resources: {
    [Language.EN_LANG_KEY]: en,
    [Language.DE_LANG_KEY]: de,
  },
  lng: Language.DE_LANG_KEY,
  fallbackLng: Language.EN_LANG_KEY,
  defaultNS: 'translation',
  ns: 'translation',
  interpolation: {
    escapeValue: false,
  },
});
