/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore } from 'redux';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './reducers/root.reducer';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

const getValues: () => { store: any; persistor: Persistor } = () => {
  const store = createStore(persistedReducer);
  const persistor: Persistor = persistStore(store);
  return { store, persistor } as any;
};

export default getValues;
