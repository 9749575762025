import styled from 'styled-components';
import { FlexPosition } from '../enums/flex-position.enum';
import { TextAlign } from '../enums/text-align.enum';
import { colors, fonts } from './Theme';

export interface TextProps {
  color?: string;
  lineHeight?: number;
  fontSize?: number;
  fontWeight?: number;
  letterSpacing?: number;
  textAlign?: TextAlign;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
  maxWidth?: number;
  alignSelf?: FlexPosition;
}

const getMargin = (props: TextProps) => {
  const marginTop: number = props.marginTop ?? 0;
  const marginLeft: number = props.marginLeft ?? 0;
  const marginRight: number = props.marginRight ?? 0;
  const marginBottom: number = props.marginBottom ?? 0;
  const result = `${marginTop}px ${marginLeft}px ${marginBottom}px ${marginRight}px`;
  return result;
};

export default {
  NanoTitle: styled.p<TextProps>`
    margin: 0;
    font-family: ${fonts.CinderBlock100};
    text-transform: uppercase;
    font-size: ${(props: TextProps) => props.fontSize ?? 90}px;
    letter-spacing: ${(props: TextProps) => props.letterSpacing ?? 2}px;
    text-align: ${(props: TextProps) => props.textAlign ?? TextAlign.LEFT};
    margin: ${(props: TextProps) => getMargin(props)};
    color: ${(props: TextProps) => (props.color ? props.color : colors.basic.WHITE)};
    max-width: ${(props: TextProps) => props.maxWidth + 'px' ?? null};
    line-height: ${(props: TextProps) => props.lineHeight + 'px' ?? null};
    align-self: ${(props: TextProps) => props.alignSelf ?? null};
  `,
  NanoSubTitle: styled.p<TextProps>`
    margin: 0;
    font-family: ${fonts.SharpGroteskSemiBold15};
    font-size: ${(props: TextProps) => props.fontSize ?? 28}px;
    letter-spacing: ${(props: TextProps) => props.letterSpacing ?? null}px;
    text-align: ${(props: TextProps) => props.textAlign ?? TextAlign.LEFT};
    margin: ${(props: TextProps) => getMargin(props)};
    color: ${(props: TextProps) => (props.color ? props.color : colors.basic.WHITE)};
    max-width: ${(props: TextProps) => props.maxWidth + 'px' ?? null};
    line-height: ${(props: TextProps) => props.lineHeight + 'px' ?? null};
    align-self: ${(props: TextProps) => props.alignSelf ?? null};
  `,
  NanoHeadingSGSB19: styled.label<TextProps>`
    margin: 0;
    font-family: ${fonts.SharpGroteskSemiBold19};
    font-size: ${(props: TextProps) => props.fontSize ?? 12}px;
    letter-spacing: ${(props: TextProps) => props.letterSpacing ?? null}px;
    text-align: ${(props: TextProps) => props.textAlign ?? TextAlign.LEFT};
    margin: ${(props: TextProps) => getMargin(props)};
    font-weight: ${(props: TextProps) => props.fontWeight ?? 700};
    color: ${(props: TextProps) => (props.color ? props.color : colors.basic.WHITE)};
    max-width: ${(props: TextProps) => props.maxWidth + 'px' ?? null};
    line-height: ${(props: TextProps) => props.lineHeight + 'px' ?? null};
    align-self: ${(props: TextProps) => props.alignSelf ?? null};
  `,
  NanoHeadingSGSB15: styled.label<TextProps>`
    margin: 0;
    font-family: ${fonts.SharpGroteskSemiBold15};
    font-size: ${(props: TextProps) => props.fontSize ?? 12}px;
    letter-spacing: ${(props: TextProps) => props.letterSpacing ?? null}px;
    text-align: ${(props: TextProps) => props.textAlign ?? TextAlign.LEFT};
    margin: ${(props: TextProps) => getMargin(props)};
    font-weight: ${(props: TextProps) => props.fontWeight ?? 700};
    color: ${(props: TextProps) => (props.color ? props.color : colors.basic.WHITE)};
    max-width: ${(props: TextProps) => props.maxWidth + 'px' ?? null};
    line-height: ${(props: TextProps) => props.lineHeight + 'px' ?? null};
    align-self: ${(props: TextProps) => props.alignSelf ?? null};
  `,
  NanoBigTextSGSB15: styled.p<TextProps>`
    margin: 0;
    font-family: ${fonts.SharpGroteskSemiBold15};
    font-size: ${(props: TextProps) => props.fontSize ?? 28}px;
    letter-spacing: ${(props: TextProps) => props.letterSpacing ?? null}px;
    text-align: ${(props: TextProps) => props.textAlign ?? TextAlign.LEFT};
    margin: ${(props: TextProps) => getMargin(props)};
    font-weight: ${(props: TextProps) => props.fontWeight ?? 700};
    color: ${(props: TextProps) => (props.color ? props.color : colors.basic.WHITE)};
    max-width: ${(props: TextProps) => props.maxWidth + 'px' ?? null};
    line-height: ${(props: TextProps) => props.lineHeight + 'px' ?? null};
    align-self: ${(props: TextProps) => props.alignSelf ?? null};
  `,
  NanoText: styled.p<TextProps>`
    margin: 0;
    font-family: ${fonts.SharpGrotesk19};
    font-size: ${(props: TextProps) => props.fontSize ?? 16}px;
    letter-spacing: ${(props: TextProps) => props.letterSpacing ?? null}px;
    text-align: ${(props: TextProps) => props.textAlign ?? TextAlign.LEFT};
    margin: ${(props: TextProps) => getMargin(props)};
    font-weight: ${(props: TextProps) => props.fontWeight ?? 400};
    color: ${(props: TextProps) => (props.color ? props.color : colors.basic.WHITE)};
    max-width: ${(props: TextProps) => props.maxWidth + 'px' ?? null};
    line-height: ${(props: TextProps) => props.lineHeight + 'px' ?? null};
    align-self: ${(props: TextProps) => props.alignSelf ?? null};
  `,
  NanoTextSGSB19: styled.p<TextProps>`
    margin: 0;
    font-family: ${fonts.SharpGroteskSemiBold19};
    font-size: ${(props: TextProps) => props.fontSize ?? 16}px;
    letter-spacing: ${(props: TextProps) => props.letterSpacing ?? null}px;
    text-align: ${(props: TextProps) => props.textAlign ?? TextAlign.LEFT};
    margin: ${(props: TextProps) => getMargin(props)};
    font-weight: ${(props: TextProps) => props.fontWeight ?? 400};
    color: ${(props: TextProps) => (props.color ? props.color : colors.basic.WHITE)};
    max-width: ${(props: TextProps) => props.maxWidth + 'px' ?? null};
    line-height: ${(props: TextProps) => props.lineHeight + 'px' ?? null};
    align-self: ${(props: TextProps) => props.alignSelf ?? null};
  `,
  NanoLink: styled.a<TextProps>`
    font-family: ${fonts.SharpGroteskSemiBold15};
    border-bottom: 2px solid ${colors.primary.giantRed};
    text-decoration: none;
    margin: 0;
    font-size: ${(props: TextProps) => props.fontSize ?? 16}px;
    letter-spacing: ${(props: TextProps) => props.letterSpacing ?? null}px;
    text-align: ${(props: TextProps) => props.textAlign ?? TextAlign.LEFT};
    margin: ${(props: TextProps) => getMargin(props)};
    color: ${(props: TextProps) => (props.color ? props.color : colors.basic.WHITE)};
    max-width: ${(props: TextProps) => props.maxWidth + 'px' ?? null};
    line-height: ${(props: TextProps) => props.lineHeight ?? 26}px;
    align-self: ${(props: TextProps) => props.alignSelf ?? null};
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: ${colors.primary.giantRed};
        border-bottom: 2px solid ${colors.basic.WHITE};
      }
    }

    &:active {
      border-bottom: 2px solid ${colors.secondary.slowGrey};
    }
  `,
  NanoLinkSGSB19: styled.a<TextProps>`
    font-family: ${fonts.SharpGroteskSemiBold19};
    border-bottom: 2px solid ${colors.primary.giantRed};
    text-decoration: none;
    margin: 0;
    font-size: ${(props: TextProps) => props.fontSize ?? 16}px;
    letter-spacing: ${(props: TextProps) => props.letterSpacing ?? null}px;
    text-align: ${(props: TextProps) => props.textAlign ?? TextAlign.LEFT};
    margin: ${(props: TextProps) => getMargin(props)};
    color: ${(props: TextProps) => (props.color ? props.color : colors.basic.WHITE)};
    max-width: ${(props: TextProps) => props.maxWidth + 'px' ?? null};
    line-height: ${(props: TextProps) => props.lineHeight ?? 26}px;
    align-self: ${(props: TextProps) => props.alignSelf ?? null};
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: ${colors.primary.giantRed};
        border-bottom: 2px solid ${colors.basic.WHITE};
      }
    }

    &:active {
      border-bottom: 2px solid ${colors.secondary.slowGrey};
    }
  `,
};
