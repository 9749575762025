import React, { FunctionComponent } from 'react';

const ListTwo: FunctionComponent = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19" stroke="white" strokeWidth="2" />
    <path
      d="M26.0725 29V25.528H19.1005V25.388C19.1285 23.568 20.6125 22.476 22.2085 21.244C24.0845 19.816 26.1285 18.22 26.1285 15V13.572C26.1285 10.38 24.2805 8.644 20.6125 8.644C16.9725 8.644 15.0405 10.408 15.0405 13.572V16.484H18.7365V13.712C18.7365 12.536 19.2965 11.808 20.6125 11.808C21.9285 11.808 22.4325 12.452 22.4325 13.712V15C22.4325 16.792 20.8925 17.856 19.1845 19.116C17.1685 20.6 14.9565 22.392 14.9565 25.892V29H26.0725Z"
      fill="white"
    />
  </svg>
);

export default ListTwo;
