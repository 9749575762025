import React, { FunctionComponent } from 'react';

const Growth: FunctionComponent = () => (
  <svg viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M48.7022 30.2976H47.2402C43.203 30.2976 39.9302 33.5704 39.9302 37.6077V40.5317H41.3922C45.4294 40.5317 48.7022 37.2589 48.7022 33.2216V30.2976Z"
        fill="#D90855"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1578 22.9873H32.6198C36.6571 22.9873 39.9299 26.2601 39.9299 30.2974V33.2214H38.4679C34.4307 33.2214 31.1578 29.9486 31.1578 25.9113V22.9873Z"
        fill="#D90855"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.9302 50.2175V15.1357"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 55.8827H31.1581C31.1581 54.2678 32.4672 52.9587 34.0821 52.9587C34.8112 52.9587 35.4698 53.2351 35.9819 53.6766C36.8534 51.5418 38.9439 50.0347 41.3922 50.0347C44.622 50.0347 47.2402 52.6529 47.2402 55.8827H61.8603"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 14H27" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M14 1L14 27" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M23.1924 4.80762L4.80761 23.1924" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M23.1924 23.1924L4.80761 4.80761" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="14" cy="14" r="10" fill="#19194A" />
      <circle cx="14.0002" cy="13.9997" r="7.43182" stroke="white" strokeWidth="1.5" />
    </g>
  </svg>
);

export default Growth;
