import React from 'react';
import { Range, getTrackBackground } from 'react-range';
import styled from 'styled-components';
import { RangeSliderFields } from '../../enums/form-elements.enum';
import { SetRangeSliderValuesPayload } from '../../store/actions/user.action';
import { colors, fonts } from '../../styles/Theme';

export interface RangeSliderProps {
  type: RangeSliderFields;
  step: number;
  values: number[];
  min: number;
  max: number;
  changeEvent: (value: SetRangeSliderValuesPayload) => void;
}

export const RangeSlider: React.FC<RangeSliderProps> = (props: RangeSliderProps) => {
  const convertNumberToCurrency = (number: number) => {
    return new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(number) + ' €';
  };

  const onChangeRangeSlider = (values: number[]) => {
    props.changeEvent({
      key: props.type,
      values,
    });
  };

  return (
    <RangeContainer>
      <Range
        values={props.values}
        step={props.step}
        min={props.min}
        max={props.max}
        onChange={values => {
          onChangeRangeSlider(values);
        }}
        renderTrack={params => (
          <TrackContainer onMouseDown={params.props.onMouseDown} onTouchStart={params.props.onTouchStart}>
            <Track
              ref={params.props.ref}
              style={{
                background: getTrackBackground({
                  values: props.values,
                  colors: [colors.secondary.microBlue, colors.basic.WHITE, colors.secondary.microBlue],
                  min: props.min,
                  max: props.max,
                }),
              }}
            >
              {params.children}
            </Track>
          </TrackContainer>
        )}
        renderThumb={params => (
          <Thumb {...params.props}>
            {params.props.key === 0 ? (
              <ThumbTooltipBottom>{convertNumberToCurrency(props.values[params.props.key])}</ThumbTooltipBottom>
            ) : (
              <ThumbTooltipTop>{convertNumberToCurrency(props.values[params.props.key])}</ThumbTooltipTop>
            )}
          </Thumb>
        )}
      />
    </RangeContainer>
  );
};

const RangeContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Thumb = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${colors.basic.WHITE};
`;

const ThumbTooltip = styled.div`
  position: absolute;
  left: 50%;
  border: 1px solid ${colors.basic.WHITE};
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, 0);
  font-size: 12px;
  line-height: 16px;
  color: ${colors.basic.WHITE};
  font-weight: 600;
  font-family: ${fonts.SharpGroteskSemiBold19};
  white-space: nowrap;

  &:before {
    content: ' ';
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
`;

const ThumbTooltipBottom = styled(ThumbTooltip)`
  top: calc(100% + 5px);

  &:before {
    top: -5px;
    border-bottom: 4px solid ${colors.basic.WHITE};
    transform: translate(-50%, 0);
  }
`;

const ThumbTooltipTop = styled(ThumbTooltip)`
  bottom: calc(100% + 5px);

  &:before {
    content: ' ';
    position: absolute;
    bottom: -5px;
    border-top: 4px solid ${colors.basic.WHITE};
    transform: translate(-50%, 0);
  }
`;

const TrackContainer = styled.div`
  height: 36px;
  display: flex;
  width: 80%;
`;

const Track = styled.div`
  height: 5px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
`;
