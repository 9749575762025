import React, { FunctionComponent } from 'react';

const CitationIcon: FunctionComponent = () => (
  <svg width="65" height="50" viewBox="0 0 65 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.26337 50C17.4897 46.5021 25.1029 34.9794 25.1029 17.9012C25.1029 4.52674 21.1934 0 12.1399 0C3.7037 0 0 3.49793 0 12.7572C0 22.2222 3.49794 24.2798 10.6996 24.2798C11.7284 24.2798 12.7572 24.0741 13.786 23.8683C12.963 33.3333 8.23045 39.5062 0.20576 41.5638L2.26337 50ZM41.9753 50C57.2017 46.5021 64.8148 34.9794 64.8148 17.9012C64.8148 4.52674 60.9054 0 51.8519 0C43.4156 0 39.7119 3.49793 39.7119 12.7572C39.7119 22.2222 43.2099 24.2798 50.4115 24.2798C51.4403 24.2798 52.4692 24.0741 53.2922 23.8683C52.4692 33.5391 47.7366 39.5062 39.9177 41.5638L41.9753 50Z"
      fill="#2E2E63"
    />
  </svg>
);

export default CitationIcon;
