import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { NextLevelAPI } from '../../api/api';
import ArrowIcon from '../../assets/svgs/Arrow';
import LogoSmallIcon from '../../assets/svgs/LogoSmallIcon';
import Rectangle from '../../assets/svgs/Rectangle';
import { DefaultLayout } from '../../components/Layout/DefaultLayout';
import { NavigationRoute } from '../../enums/navigation.enum';
import { TextAlign } from '../../enums/text-align.enum';
import { Vacancy, NextLevelData } from '../../interfaces/Vacancy.interface';
import { UserActionType } from '../../store/actions/user.action';
import Layout from '../../styles/Layout';
import { colors } from '../../styles/Theme';
import Typography from '../../styles/Typography';
interface PositionStyle {
  isFirst?: boolean;
}

export const Start: React.FC = () => {
  const api: NextLevelAPI = NextLevelAPI.getInstance();
  const [availableVacancies, setAvailableVacancies] = useState<Vacancy[]>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      if (!availableVacancies.length) {
        const vacancyData: NextLevelData = await api.getVacancys();
        setAvailableVacancies(vacancyData.vacancies);
      }
    }
    fetchData();
  });

  const onVacancySelection = (vacancy: Vacancy) => {
    dispatch({ type: UserActionType.SET_SELECTED_VACANCY, payload: vacancy.vacancyName });
    history.push(NavigationRoute.VACANCY_DETAIL + '/' + vacancy.key);
  };

  const getVacancies = () => {
    return (
      <VacancyWrapper>
        {availableVacancies.map((vacancy, index) => {
          return (
            <VacancyElement key={vacancy.key} onClick={() => onVacancySelection(vacancy)} isFirst={index === 0}>
              <Typography.NanoHeadingSGSB19 fontSize={16}> {vacancy.vacancyName}</Typography.NanoHeadingSGSB19>{' '}
              <ArrowIcon />
            </VacancyElement>
          );
        })}
      </VacancyWrapper>
    );
  };

  const SuccessContent = (
    <Layout.DefaultSceneWrapper>
      <LogoWrapper>
        <LogoSmallIcon />
      </LogoWrapper>

      <Typography.NanoBigTextSGSB15 textAlign={TextAlign.CENTER} marginTop={24}>
        {'Du glaubst an eine Welt, in der Technologie jeden befähigen kann, persönlich zu wachsen?'}
      </Typography.NanoBigTextSGSB15>

      <Teaser>
        <Typography.NanoBigTextSGSB15 textAlign={TextAlign.CENTER} marginTop={24}>
          {'...dann lass uns gemeinsam'}
        </Typography.NanoBigTextSGSB15>
        <Typography.NanoTitle
          textAlign={TextAlign.CENTER}
          marginTop={4}
          marginBottom={49}
          fontSize={100}
          lineHeight={50}
        >
          WACHSEN
        </Typography.NanoTitle>

        <Rectangle />
      </Teaser>

      <Typography.NanoBigTextSGSB15>{'Was klingt spannend für dich?'}</Typography.NanoBigTextSGSB15>
      {getVacancies()}
    </Layout.DefaultSceneWrapper>
  );
  return <DefaultLayout content={SuccessContent} disableHeader={true} />;
};

const LogoWrapper = styled.div`
  width: 150px;
`;

const VacancyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
`;

const VacancyElement = styled.div<PositionStyle>`
  display: flex;
  height: 55px;
  justify-content: space-between;
  align-items: center;
  border-top: ${props => (props.isFirst ? `1px solid ${colors.secondary.white50}` : 'none')};
  border-bottom: 1px solid ${colors.secondary.white50};
`;

const Teaser = styled.div`
  position: relative;
  margin: 20px 0 40px 0;

  p {
    position: relative;
    z-index: 2;
  }

  svg {
    position: absolute;
    width: 330px;
    height: 169px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
`;
