import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/Button';
import { InputHeader } from '../../components/Input/InputHeader';
import { InputTextarea } from '../../components/Input/InputTextarea';
import { MultipleInputTextarea } from '../../components/Input/MultipeInputTextarea';
import { RangeSlider } from '../../components/Input/RangeSlider';
import { Popup } from '../../components/Popup';
import { SelectionButton } from '../../components/SelectionButton';
import { FlexPosition } from '../../enums/flex-position.enum';
import {
  InputTextareaFields,
  MultipleInputTextareaFields,
  RangeSliderFields,
  SelectionButtons,
} from '../../enums/form-elements.enum';
import { NavigationRoute } from '../../enums/navigation.enum';
import { TextAlign } from '../../enums/text-align.enum';

import { Store } from '../../interfaces/Store.interface';
import {
  SetSingleInputFieldValuePayload,
  SetSelectionButtonValuePayload,
  UserActionType,
  SetMultipleInputFieldValuePayload,
  SetRangeSliderValuesPayload,
} from '../../store/actions/user.action';

import Layout from '../../styles/Layout';
import { colors, fonts } from '../../styles/Theme';
import Typography from '../../styles/Typography';

const mapState = (state: Store) => ({
  singleInputFields: state.user.singleInputFields,
  multipleInputFields: state.user.multipleInputFields,
  checkboxFields: state.user.checkboxFields,
  selectionButtons: state.user.selectionButtons,
  selectedVacancy: state.user.selectedVacancy,
  rangeSliderFields: state.user.rangeSliderFields,
});

const mapDispatch = {
  setInputValue: (payload: SetSingleInputFieldValuePayload) => ({
    type: UserActionType.SET_INPUT_FIELD_VALUE,
    payload,
  }),
  setMultipleInputValue: (payload: SetMultipleInputFieldValuePayload) => ({
    type: UserActionType.SET_MULTIPLE_INPUT_FIELD_VALUE,
    payload,
  }),
  setSelectionValue: (payload: SetSelectionButtonValuePayload) => ({
    type: UserActionType.SET_SELECTION_BUTTON_VALUE,
    payload,
  }),
  setRangeSliderValues: (payload: SetRangeSliderValuesPayload) => ({
    type: UserActionType.SET_RANGE_SLIDER_VALUES,
    payload,
  }),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const MoreAboutYourself: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
  const [isPopupVisible, setIsPopupVisible] = React.useState(false);
  const isNextButtonDisabled = false;

  const personalGoalsValues = props.multipleInputFields.find(
    item => item.key === MultipleInputTextareaFields.PERSONA_GOALS,
  )?.values;
  const newJobParticularlyImportantValue = props.singleInputFields.find(
    item => item.key === InputTextareaFields.NEW_JOB_PARTICULARLY_IMPORTANT,
  )?.value;
  const changeOfResidenceSelectedValue = props.selectionButtons.find(
    item => item.key === SelectionButtons.CHANGE_OF_RESIDENCE,
  )?.value;

  const yourSalaryExpectations = props.rangeSliderFields.find(
    item => item.key === RangeSliderFields.YOUR_SALARY_EXPECTATIONS,
  );

  const changeOfResidenceValues = ['Ja', 'Nein'];

  const ChangeOfResidence = changeOfResidenceValues.map((value, i) => (
    <SelectionButton
      key={i}
      type={SelectionButtons.CHANGE_OF_RESIDENCE}
      clickEvent={props.setSelectionValue}
      active={value === changeOfResidenceSelectedValue}
      value={value}
    />
  ));

  const popupTextProps = {
    color: colors.primary.nanoBlue,
    fontSize: 12,
    lineHeight: 16,
    marginTop: 16,
  };

  return (
    <>
      <Layout.Wrapper>
        <InputHeader>Sag uns ein paar deiner persönlichen oder beruflichen Ziele...</InputHeader>

        <MultipleInputTextarea
          values={personalGoalsValues}
          changeEvent={props.setMultipleInputValue}
          type={MultipleInputTextareaFields.PERSONA_GOALS}
          placeholder={'Deine Antwort...'}
          minRows={1}
        />
      </Layout.Wrapper>

      <Layout.Wrapper>
        <InputHeader>
          Du wohnst in Düsseldorf oder Umgebung oder hast die Bereitschaft in Düsseldorf zu arbeiten.{' '}
          <MoreInfoLink onClick={() => setIsPopupVisible(true)}>Mehr Infos</MoreInfoLink>
        </InputHeader>
        <Layout.TwoColumnGrid>{ChangeOfResidence}</Layout.TwoColumnGrid>
      </Layout.Wrapper>

      <Layout.Wrapper>
        <InputHeader>Was ist dir bei deiner neuen Tätigkeit besonders wichtig?</InputHeader>
        <InputTextarea
          value={newJobParticularlyImportantValue}
          changeEvent={props.setInputValue}
          type={InputTextareaFields.NEW_JOB_PARTICULARLY_IMPORTANT}
          placeholder={'Deine Antwort...'}
          minRows={1}
        />
      </Layout.Wrapper>

      <Layout.Wrapper marginBottom={15}>
        <InputHeader marginBottom={40}>Deine Gehaltsvorstellung (€/Jahr):</InputHeader>
        {yourSalaryExpectations && (
          <RangeSlider
            type={RangeSliderFields.YOUR_SALARY_EXPECTATIONS}
            step={yourSalaryExpectations.step}
            values={yourSalaryExpectations.values}
            min={yourSalaryExpectations.min}
            max={yourSalaryExpectations.max}
            changeEvent={props.setRangeSliderValues}
          />
        )}
        <Note>
          Hinweis: Falls du nicht deine Gehaltsvorstellung hier angeben möchtest, änder nichts an der Einstellung oben.
        </Note>
      </Layout.Wrapper>

      <ButtonWrapper>
        <Link to={NavigationRoute.QUIZ_YOUR_CONTACT_DETAILS}>
          <Button
            isDisabled={isNextButtonDisabled}
            textAlign={TextAlign.CENTER}
            label="Weiter"
            marginTop={24}
            alignSelf={FlexPosition.CENTER}
          />
        </Link>
      </ButtonWrapper>

      <Popup title={'Mehr Infos'} closePopup={() => setIsPopupVisible(false)} isVisible={isPopupVisible}>
        <Typography.NanoTextSGSB19 {...popupTextProps}>
          Aufgrund der Covid-19-Pandemie arbeiten wir natürlich aus dem Home Office heraus. Auch außerhalb der Corona
          Zeiten ermöglichen wir unseren Mitarbeitern von zu Hause aus zu arbeiten und ihre Arbeitszeit flexibel, je
          nach individuellen Bedürfnissen zu gestalten. Jedoch finden wir es auch schön, ab und an gemeinsam im Office
          zu arbeiten. Daher solltest du die Bereitschaft haben, auch in das Office nach Düsseldorf zu kommen.
        </Typography.NanoTextSGSB19>
      </Popup>
    </>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Note = styled.div`
  margin-top: 50px;
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  font-family: ${fonts.SharpGroteskSemiBold19};
  color: ${colors.secondary.white50};
`;

const MoreInfoLink = styled(Typography.NanoLink)`
  font-family: ${fonts.SharpGroteskSemiBold19};
`;

export default connector(MoreAboutYourself);
