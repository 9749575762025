import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/Button';
import { InputHeader } from '../../components/Input/InputHeader';
import { InputText } from '../../components/Input/InputText';
import { SelectionButton } from '../../components/SelectionButton';
import { FlexPosition } from '../../enums/flex-position.enum';
import { InputTextFields, SelectionButtons } from '../../enums/form-elements.enum';
import { NavigationRoute } from '../../enums/navigation.enum';
import { TextAlign } from '../../enums/text-align.enum';
import { SingleInputField } from '../../interfaces/Form.interface';

import { Store } from '../../interfaces/Store.interface';
import {
  SetSingleInputFieldValuePayload,
  SetSelectionButtonValuePayload,
  UserActionType,
} from '../../store/actions/user.action';

import Layout from '../../styles/Layout';

const mapState = (state: Store) => ({
  singleInputFields: state.user.singleInputFields,
  selectionButtons: state.user.selectionButtons,
});

const mapDispatch = {
  setInputValue: (payload: SetSingleInputFieldValuePayload) => ({
    type: UserActionType.SET_INPUT_FIELD_VALUE,
    payload,
  }),
  setSelectionValue: (payload: SetSelectionButtonValuePayload) => ({
    type: UserActionType.SET_SELECTION_BUTTON_VALUE,
    payload,
  }),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Experience: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
  const currentEmploymentValue = props.singleInputFields.find(
    (item: SingleInputField) => item.key === InputTextFields.CURRENT_EMPLOYMENT,
  )?.value;

  const experienceInYearsSelectedValue = props.selectionButtons.find(
    item => item.key === SelectionButtons.EXPERIENCE_IN_YEARS,
  )?.value;

  const experienceInYearsValues = ['<1', '1', '2', '3', '4', '5+'];

  const ExperienceInYears = experienceInYearsValues.map((value, i) => (
    <SelectionButton
      key={i}
      type={SelectionButtons.EXPERIENCE_IN_YEARS}
      clickEvent={props.setSelectionValue}
      active={value === experienceInYearsSelectedValue}
      value={value}
    />
  ));

  const isNextButtonDisabled = experienceInYearsSelectedValue === '' || currentEmploymentValue === '';

  return (
    <>
      <Layout.Wrapper>
        <InputHeader isRequired={true}>Aktuell bin ich beschäftigt als...</InputHeader>
        <InputText
          value={currentEmploymentValue}
          changeEvent={props.setInputValue}
          type={InputTextFields.CURRENT_EMPLOYMENT}
          placeholder={'Deine Antwort...'}
        />
      </Layout.Wrapper>

      <Layout.Wrapper>
        <InputHeader isRequired={true}>Wie viele Jahre Berufserfahrung bringst du mit?</InputHeader>
        <Layout.ThreeColumnGrid>{ExperienceInYears}</Layout.ThreeColumnGrid>
      </Layout.Wrapper>

      <ButtonWrapper>
        <Link to={isNextButtonDisabled ? '#' : NavigationRoute.QUIZ_MORE_ABOUT_YOURSELF}>
          <Button
            isDisabled={isNextButtonDisabled}
            textAlign={TextAlign.CENTER}
            label="Weiter"
            marginTop={24}
            alignSelf={FlexPosition.CENTER}
          />
        </Link>
      </ButtonWrapper>
    </>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default connector(Experience);
