import React from 'react';
import styled from 'styled-components';
import Layout from '../../styles/Layout';
import Typography from '../../styles/Typography';

export const DataProtection: React.FC = () => {
  const nanoSubTitleProps = {
    marginTop: 25,
    fontSize: 22,
  };
  const nanoTextProps = {
    fontSize: 13,
  };
  const nanoLinkProps = {
    fontSize: 13,
  };
  return (
    <Layout.DefaultSceneWrapper>
      <DataProtectionContainer>
        <Typography.NanoTitle fontSize={50}>Datenschutzerklärung</Typography.NanoTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der
          Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen
          Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet. <br />
          <br />
          Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur
          „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter
          Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das
          Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das
          Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
          Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung. <br />
          <br />
          Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und
          Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über
          die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten,
          soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten. <br />
          <br />
          Unsere Datenschutzerklärung ist wie folgt gegliedert: <br />
          <br />
          I. Informationen über uns als Verantwortliche
          <br />
          II. Rechte der Nutzer und Betroffenen
          <br />
          III. Informationen zur Datenverarbeitung
          <br />
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>
          I. Informationen über uns als Verantwortliche
        </Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:
          <br />
          <br />
          NanoGiants GmbH
          <br />
          Erkrather Str. 230
          <br />
          40233 Düsseldorf
          <br />
          <br />
          E-Mail:{' '}
          <Typography.NanoLinkSGSB19 {...nanoLinkProps} href="mailto:hello@nanogiants.de">
            hello@nanogiants.de
          </Typography.NanoLinkSGSB19>
          <br />
          Telefon:{' '}
          <Typography.NanoLinkSGSB19 {...nanoLinkProps} href="tel:+49 (0)211 936 72720">
            +49 (0)211 936 72720
          </Typography.NanoLinkSGSB19>
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>II. Rechte der Nutzer und Betroffenen</Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das
          Recht
          <List>
            <li>
              auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten,
              auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);
            </li>
            <li>
              auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);
            </li>
            <li>
              auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit
              eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung
              nach Maßgabe von Art. 18 DSGVO;
            </li>
            <li>
              auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an
              andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);
            </li>
            <li>
              auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden
              Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl.
              auch Art. 77 DSGVO).
            </li>
            Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter
            offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der
            Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung
            besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden
            ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.
            <br />
            <br />
            Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige
            Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1
            lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke
            der Direktwerbung statthaft.
          </List>
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>
          III. Informationen zur Datenverarbeitung
        </Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck
          der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und
          nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>Kontaktanfragen / Kontaktmöglichkeit</Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen
          Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre
          Anfrage erforderlich - ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt
          beantworten.
          <br />
          <br />
          Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.
          <br />
          <br />
          Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine
          gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden
          Vertragsabwicklung.
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>
          Verlinkung Social-Media über Grafik oder Textlink
        </Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          Wir bewerben auf unserer Webseite auch Präsenzen auf den nachstehend aufgeführten sozialen Netzwerken. Die
          Einbindung erfolgt dabei über eine verlinkte Grafik des jeweiligen Netzwerks. Durch den Einsatz dieser
          verlinkten Grafik wird verhindert, dass es bei dem Aufruf einer Website, die über eine Social-Media-Bewerbung
          verfügt, automatisch zu einem Verbindungsaufbau zum jeweiligen Server des sozialen Netzwerks kommt, um eine
          Grafik des jeweiligen Netzwerkes selbst darzustellen. Erst durch einen Klick auf die entsprechende Grafik wird
          der Nutzer zu dem Dienst des jeweiligen sozialen Netzwerks weitergeleitet.
          <br />
          <br />
          Nach der Weiterleitung des Nutzers werden durch das jeweilige Netzwerk Informationen über den Nutzer erfasst.
          Es kann hierbei nicht ausgeschlossen werden, dass eine Verarbeitung der so erhobenen Daten in den USA
          stattfindet.
          <br />
          <br />
          Dies sind zunächst Daten wie IP-Adresse, Datum, Uhrzeit und besuchte Seite. Ist der Nutzer währenddessen in
          seinem Benutzerkonto des jeweiligen Netzwerks eingeloggt, kann der Netzwerk-Betreiber ggf. die gesammelten
          Informationen des konkreten Besuchs des Nutzers dem persönlichen Account des Nutzers zuordnen. Interagiert der
          Nutzer über einen „Teilen“-Button des jeweiligen Netzwerks, können diese Informationen in dem persönlichen
          Benutzerkonto des Nutzers gespeichert und ggf. veröffentlicht werden. Will der Nutzer verhindern, dass die
          gesammelten Informationen unmittelbar seinem Benutzerkonto zugeordnet werden, muss er sich vor dem Anklicken
          der Grafik ausloggen. Zudem besteht die Möglichkeit, das jeweilige Benutzerkonto entsprechend zu
          konfigurieren.
          <br />
          <br />
          Folgende soziale Netzwerke werden in unsere Seite durch Verlinkung eingebunden:
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>facebook</Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, ein Tochterunternehmen der Facebook Inc.,
          1601 S. California Ave., Palo Alto, CA 94304, USA.
          <br />
          <br />
          Datenschutzerklärung:{' '}
          <Typography.NanoLinkSGSB19 {...nanoLinkProps} href="https://www.facebook.com/policy.php">
            https://www.facebook.com/policy.php
          </Typography.NanoLinkSGSB19>
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>twitter</Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA
          <br />
          <br />
          Datenschutzerklärung:{' '}
          <Typography.NanoLinkSGSB19 {...nanoLinkProps} href="https://twitter.com/privacy">
            https://twitter.com/privacy
          </Typography.NanoLinkSGSB19>
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>LinkedIn</Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, ein Tochterunternehmen der
          LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085 USA.
          <br />
          <br />
          Datenschutzerklärung:{' '}
          <Typography.NanoLinkSGSB19 {...nanoLinkProps} href="https://www.linkedin.com/legal/privacy-policy">
            https://www.linkedin.com/legal/privacy-policy
          </Typography.NanoLinkSGSB19>
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>Instagram</Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, ein Tochterunternehmen der Facebook Inc.,
          1601 S. California Ave., Palo Alto, CA 94304, USA.
          <br />
          <br />
          Datenschutzerklärung:{' '}
          <Typography.NanoLinkSGSB19 {...nanoLinkProps} href="https://help.instagram.com/519522125107875">
            https://help.instagram.com/519522125107875
          </Typography.NanoLinkSGSB19>
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>Matomo (vormals: PIWIK)</Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          In unserem Internetauftritt setzen wir Matomo (ehemals: {'„PIWIK“'}) ein. Hierbei handelt es sich um eine
          Open-Source-Software, mit der wir die Benutzung unseres Internetauftritts analysieren können. Hierbei werden
          Ihre IP-Adresse, die Website(s) unseres Internetauftritts, die Sie besuchen, die Website, von der aus Sie auf
          unseren Internetauftritt gewechselt haben (Referrer URL), Ihre Verweildauer auf unserem Internetauftritt sowie
          die Häufigkeit des Aufrufs einer unserer Websites verarbeitet.
          <br />
          <br />
          Matomo wird hierbei ohne die Verwendung von Cookies genutzt, sondern mittels eines{' '}
          {'"digitalen Fingerabdrucks"'}, welcher alle 24 Stunden geändert wird.
          <br />
          <br />
          Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse und
          Optimierung unseres Internetauftritts.
          <br />
          <br />
          Allerdings nutzen wir Matomo mit der Anonymisierungsfunktion „Automatically Anonymize Visitor IPs“. Diese
          Anonymisierungsfunktion kürzt Ihre IP-Adresse um zwei Bytes, sodass eine Zuordnung zu Ihnen bzw. zu dem von
          Ihnen genutzten Internetanschluss unmöglich ist.
          <br />
          <br />
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>Vimeo</Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          Zur Darstellung von Videos setzen wir auf unserer Internetseite {'„Vimeo“'} ein. Hierbei handelt es sich um
          einen Dienst der Vimeo, LL C, 555 West 18 th Street, New York, New York 10011, USA, nachfolgend nur{' '}
          {'„Vimeo“'} genannt, ein.
          <br />
          <br />
          Teilweise erfolgt die Verarbeitung der Nutzerdaten auf Servern von Vimeo in den USA.
          <br />
          <br />
          Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der
          Qualitätsverbesserung unseres Internetauftritts.
          <br />
          <br />
          Besuchen Sie eine Seite unseres Internetauftritts, in die ein Video eingebettet ist, wird zur Darstellung des
          Videos eine Verbindung zu den Servern von Vimeo in den USA hergestellt. Aus technischen Gründen ist es dabei
          notwendig, dass Vimeo Ihre IP - Adresse verarbeitet. Daneben werden aber auch Datum und Uhrzeit des Besuchs
          unserer Internetseiten erfasst.
          <br />
          <br />
          Sollten Sie gleichzeitig bei Vimeo eingeloggt sein, während Sie eine unserer Internetseiten besuchen, in die
          ein Vimeo-Video eingebettet ist, weist Vimeo die so gesammelten Informationen womöglich Ihrem dortigen
          persönlichen Nutzerkonto zu. Wenn Sie das verhindern möchten, müssen Sie sich entweder vor dem Besuch unseres
          Internetauftritts bei Vimeo ausloggen oder Ihr Nutzerkonto bei Vimeo entsprechend konfigurieren.
          <br />
          <br />
          Zum Zwecke der Funktionalität und Nutzungsanalyse setzt Vimeo den Webanalysedienst Google Analytics ein. Durch
          Google Analytics werden Cookies über Ihren Internet-Browser auf Ihrem Endgerät gespeichert und Informationen
          über die Benutzung unserer Internetseiten, in die ein Vimeo- Video eingebettet ist, an Google übersandt.
          Hierbei kann nicht ausgeschlossen werden, dass Google diese Informationen in den USA verarbeitet.
          <br />
          <br />
          Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der
          Cookies durch die entsprechenden Einstellungen in Ihrem Internet-Browser zu verhindern. Einzelheiten hierzu
          finden Sie vorstehend unter dem Punkt „Cookies“.
          <br />
          <br />
          Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der
          Qualitätsverbesserung unseres Internetauftritts und in dem berechtigten Interesse von Vimeo , das
          Nutzerverhalten statistisch zu Optimierungs- und Marketingzwecken zu analysieren.
          <br />
          <br />
          Vimeo bietet unter
          <br />
          <br />
          http://vimeo.com/privacy
          <br />
          <br />
          weitere Informationen zur Erhebung und Nutzung der Daten sowie zu Ihren Rechten und Möglichkeiten zum Schutz
          Ihrer Privatsphäre an.
        </Typography.NanoText>
        <Typography.NanoSubTitle {...nanoSubTitleProps}>Quelle</Typography.NanoSubTitle>
        <Typography.NanoText {...nanoTextProps} marginTop={11}>
          <Typography.NanoLinkSGSB19
            {...nanoLinkProps}
            href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
          >
            Muster-Datenschutzerklärung
          </Typography.NanoLinkSGSB19>{' '}
          der <br />
          <Typography.NanoLinkSGSB19
            {...nanoLinkProps}
            href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html"
          >
            Anwaltskanzlei Weiß & Partner
          </Typography.NanoLinkSGSB19>
        </Typography.NanoText>
      </DataProtectionContainer>
    </Layout.DefaultSceneWrapper>
  );
};

const DataProtectionContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const List = styled.ul`
  padding-left: 15px;
`;
