import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { NavigationRoute } from '../enums/navigation.enum';
import Typography from '../styles/Typography';

export const Footer: React.FC = () => {
  const history = useHistory();

  const goToDataProtection = () => {
    history.push(NavigationRoute.DATA_PROTECTION);
  };

  return (
    <FooterWrapper>
      <FooterLink
        href="https://nanogiants.de/impressum.html"
        rel="noreferrer"
        title="Nanogiants Impressum"
        target="_blank"
      >
        Impressum
      </FooterLink>
      <FooterLink onClick={goToDataProtection}>Datenschutz</FooterLink>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  box-sizing: border-box;
  padding: 15px 15px 30px 15px;
  width: 100%;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
`;

const FooterLink = styled(Typography.NanoLinkSGSB19)`
  font-size: 12px;
  margin: 0 15px;
`;
