import React from 'react';
import { DefaultLayout } from '../../components/Layout/DefaultLayout';
import { ProgressBar } from '../../components/Progressbar';
import { TextAlign } from '../../enums/text-align.enum';
import Layout from '../../styles/Layout';
import Typography from '../../styles/Typography';
import Experience from './Experience';
import MoreAboutYourself from './MoreAboutYourself';
import YourContactDetails from './YourContactDetails';

export enum QuizPages {
  YOUR_EXPERIENCE = 'YOUR_EXPERIENCE',
  MORE_ABOUT_YOURSELF = 'MORE_ABOUT_YOURSELF',
  YOUR_CONTACT_DETAILS = 'YOUR_CONTACT_DETAILS',
}

export interface QuizItem {
  key: QuizPages;
  title: string;
  pageElement: React.FC;
}

export interface QuizProps {
  page: QuizPages;
}

export const Quiz: React.FC<QuizProps> = (props: QuizProps) => {
  const quizData: QuizItem[] = [
    {
      key: QuizPages.YOUR_EXPERIENCE,
      title: 'Deine Erfahrung',
      pageElement: () => <Experience />,
    },
    {
      key: QuizPages.MORE_ABOUT_YOURSELF,
      title: 'Mehr zu dir',
      pageElement: () => <MoreAboutYourself />,
    },
    {
      key: QuizPages.YOUR_CONTACT_DETAILS,
      title: 'Deine Kontaktdaten',
      pageElement: () => <YourContactDetails />,
    },
  ];

  const currentQuizItem = quizData?.find(quizItem => quizItem.key === props.page) ?? quizData[0];
  const currentIndex = quizData?.findIndex(quizItem => quizItem.key === props.page) + 1;
  const PageElement = currentQuizItem.pageElement;

  const QuizContent = (
    <Layout.DefaultSceneWrapper>
      <Layout.Wrapper marginTop={0}>
        <Typography.NanoSubTitle textAlign={TextAlign.LEFT}>{currentQuizItem.title}</Typography.NanoSubTitle>

        <ProgressBar currentIndex={currentIndex} length={quizData.length} />

        <PageElement />
      </Layout.Wrapper>
    </Layout.DefaultSceneWrapper>
  );

  return <DefaultLayout content={QuizContent} />;
};
