import * as React from 'react';
import styled from 'styled-components';
import CitationIcon from '../../../assets/svgs/CitationIcon';
import { Avatar } from '../../../components/Avatar';
import { FlexPosition } from '../../../enums/flex-position.enum';
import { PersonEnum } from '../../../enums/person.enum';
import { TextAlign } from '../../../enums/text-align.enum';
import { colors } from '../../../styles/Theme';
import Typography from '../../../styles/Typography';

export interface TestimonialListProps {
  testimonialList?: Testimonial[];
}

export interface Testimonial {
  person: PersonEnum;
  text: string;
  personName: string;
}

interface PersonProps {
  flexPosition?: FlexPosition;
}

const TestimonialList: React.FC<TestimonialListProps> = (props: TestimonialListProps) => {
  const isNormalRow = (index: number) => {
    if (index % 2 === 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {props.testimonialList?.length ? (
        <>
          <Typography.NanoHeadingSGSB15 fontSize={32} marginTop={60} textAlign={TextAlign.CENTER}>
            Ein paar Stimmen von unseren NanoGiants
          </Typography.NanoHeadingSGSB15>
          {props.testimonialList.map((item: Testimonial, index: number) => {
            return (
              <PersonWrapper key={item.person}>
                <PersonContainer>
                  {isNormalRow(index) ? (
                    <>
                      <PersonAvatarContainer>
                        <Avatar person={item.person} disableName={true} />
                        <Typography.NanoHeadingSGSB19 marginTop={10} fontSize={16} color={colors.secondary.white50}>
                          {item.personName}
                        </Typography.NanoHeadingSGSB19>
                      </PersonAvatarContainer>
                      <CitationIcon />
                    </>
                  ) : (
                    <>
                      <CitationIcon />
                      <PersonAvatarContainer flexPosition={FlexPosition.FLEX_END}>
                        <Avatar person={item.person} disableName={true} />
                        <Typography.NanoHeadingSGSB19 marginTop={10} fontSize={16} color={colors.secondary.white50}>
                          {item.personName}
                        </Typography.NanoHeadingSGSB19>
                      </PersonAvatarContainer>
                    </>
                  )}
                </PersonContainer>
                <Typography.NanoText marginTop={8} fontSize={13}>
                  {item.text}
                </Typography.NanoText>
              </PersonWrapper>
            );
          })}
        </>
      ) : null}
    </>
  );
};

const PersonWrapper = styled.div`
  margin-top: 34px;
  min-width: 335px;
`;

const PersonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: flex-end;
`;

const PersonAvatarContainer = styled.div<PersonProps>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.flexPosition ? props.flexPosition : FlexPosition.FLEX_START)};
`;

export default TestimonialList;
