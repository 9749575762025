import React from 'react';
import { NanoLink, NanoLinkType } from '../../../components/Layout/NanoLink';
import { TextAlign } from '../../../enums/text-align.enum';
import { Vacancy } from '../../../interfaces/Vacancy.interface';
import { colors } from '../../../styles/Theme';
import Typography from '../../../styles/Typography';

type VacancyHeaderProps = {
  vacancy?: Vacancy;
};

export const VacancyHeader: React.FC<VacancyHeaderProps> = (props: VacancyHeaderProps) => {
  return (
    <>
      {props.vacancy?.key ? (
        <>
          <Typography.NanoHeadingSGSB19
            fontSize={28}
            color={colors.primary.giantRed}
            marginBottom={15}
            textAlign={TextAlign.LEFT}
          >
            Deine Rolle als
          </Typography.NanoHeadingSGSB19>
          <Typography.NanoTitle
            fontSize={70}
            textAlign={TextAlign.LEFT}
            marginTop={-20}
            marginBottom={20}
            lineHeight={65}
          >
            {props.vacancy?.vacancyName}
          </Typography.NanoTitle>
          <Typography.NanoText lineHeight={26}>{props.vacancy?.vacancyText}</Typography.NanoText>
          {['fullstack-dev', 'ios-android-dev'].includes(props.vacancy?.key) && (
            <NanoLink
              marginTop={15}
              type={NanoLinkType.HYPERLINK}
              text={'Techradar der NanoGiants'}
              target={'https://techradar.nanogiants.de/'}
            />
          )}
        </>
      ) : null}
    </>
  );
};
