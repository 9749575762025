import React, { FunctionComponent } from 'react';

const Teamspirit: FunctionComponent = () => (
  <svg viewBox="0 0 59 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4001 19.6429C12.999 9.17008 22.5102 1.40552 33.8474 1.40552C47.1845 1.40552 58 12.1568 58 25.4147C58 38.6725 47.1845 49.4238 33.8474 49.4238C24.3023 49.4238 16.0518 43.9209 12.1295 35.9355"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.8475 1.40552V49.4238"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 25.4146L58 25.4146"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1099 35.4985V49.4241"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.8473 49.4229H4.86426"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19.3558 24.9355H4.86426L12.11 35.4995L19.3558 24.9355Z" fill="#D90855" />
    <path
      d="M15.709 41.256C20.1337 36.2525 26.6211 33.0977 33.8475 33.0977C41.074 33.0977 47.5614 36.2525 51.9861 41.256"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.9864 9.5822C47.5569 14.5761 41.0743 17.7308 33.8479 17.7308C26.6118 17.7308 20.1244 14.5713 15.6948 9.56299"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 19.6523L12.1102 35.9784L23.2203 19.6523H1Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Teamspirit;
