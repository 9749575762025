import React from 'react';
import styled from 'styled-components';
import Layout, { WrapperProps } from '../../styles/Layout';
import { colors, fonts } from '../../styles/Theme';

export interface InputHeaderProps extends WrapperProps {
  children: React.ReactNode;
  isLabel?: boolean;
  isRequired?: boolean;
  isError?: boolean;
}

export const InputHeader: React.FC<InputHeaderProps> = (props: InputHeaderProps) => {
  const marginProps = {
    marginTop: 0,
    marginBottom: 0,
  };

  return (
    <Header {...props}>
      {props.isLabel ? (
        <Label {...props}>
          <Layout.Wrapper {...marginProps}>
            {props.children}{' '}
            <LabelAnnotation {...props}>
              <span>({props.isRequired ? 'Pflichtfeld' : 'optional'})</span>
            </LabelAnnotation>
          </Layout.Wrapper>
        </Label>
      ) : (
        <Description {...props}>
          <Layout.Wrapper {...marginProps}>
            {props.children}{' '}
            <Annotation {...props}>
              <span>({props.isRequired ? 'Pflichtfeld' : 'optional'})</span>
            </Annotation>
          </Layout.Wrapper>
        </Description>
      )}
    </Header>
  );
};

const Description = styled.h4`
  position: relative;
  white-space: pre-line;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  font-family: ${fonts.SharpGroteskSemiBold19};
  line-height: 26px;
  color: ${colors.basic.WHITE};
  padding-bottom: 10px;
`;
const Header = styled.div<InputHeaderProps>`
  position: relative;
  display: grid;
  grid-area: header;
  grid-template-areas: 'label';
  margin-left: ${props => props.marginLeft ?? 0}px;
  margin-top: ${props => props.marginTop ?? 0}px;
  margin-right: ${props => props.marginRight ?? 0}px;
  margin-bottom: ${props => props.marginBottom ?? 0}px;
`;
const Label = styled.label<InputHeaderProps>`
  padding-bottom: 6px;
  display: grid;
  align-items: center;
  grid-area: label;
  font-size: 12px;
  line-height: 16px;
  font-family: ${fonts.SharpGroteskSemiBold19};
  color: ${props => (props.isError ? colors.primary.giantRed : colors.basic.WHITE)};
  text-align: left;
`;
const Annotation = styled.label<InputHeaderProps>`
  float: right;
  font-size: 10px;
  line-height: 26px;
  font-family: ${fonts.SharpGroteskSemiBold19};
  color: ${props => (props.isError ? colors.primary.giantRed : colors.basic.WHITE)};
  text-align: right;

  span {
    position: relative;
    bottom: -2px;
    line-height: 14px;
  }
`;

const LabelAnnotation = styled(Annotation)`
  line-height: 16px;
`;
