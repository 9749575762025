import React from 'react';
import styled from 'styled-components';
import { SelectionButtons } from '../enums/form-elements.enum';
import { SetSelectionButtonValuePayload } from '../store/actions/user.action';
import { colors, fonts } from '../styles/Theme';

interface SelectionButtonProps {
  type: SelectionButtons;
  value: string;
  active: boolean;
  clickEvent: (value: SetSelectionButtonValuePayload) => void;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
}

export const SelectionButton: React.FC<SelectionButtonProps> = (props: SelectionButtonProps) => {
  const clickOnButton = () => {
    props.clickEvent({
      key: props.type,
      selectedValue: props.value,
    });
  };

  return (
    <SelectionButtonWrapper {...props} onClick={clickOnButton}>
      {props.value}
    </SelectionButtonWrapper>
  );
};

const SelectionButtonWrapper = styled.div<SelectionButtonProps>`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 30px;
  padding-top: ${props => props.paddingTop + 'px' ?? '30px'};
  padding-right: ${props => props.paddingRight + 'px' ?? '30px'};
  padding-bottom: ${props => props.paddingBottom + 'px' ?? '30px'};
  padding-left: ${props => props.paddingLeft + 'px' ?? '30px'};
  border: 1px solid ${colors.basic.WHITE};

  border-radius: 4px;
  background-color: ${props => (props.active ? colors.basic.WHITE : colors.primary.nanoBlueLight)};
  align-items: center;
  justify-content: center;

  color: ${props => (props.active ? colors.primary.nanoBlue : colors.basic.WHITE)};
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  font-family: ${fonts.SharpGroteskSemiBold19};
  cursor: pointer;

  transition: color 0.2s ease-in, background 0.2s ease-in;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${colors.basic.WHITE};
      color: ${colors.primary.nanoBlue};
    }
  }
`;
