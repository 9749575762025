import axios from 'axios';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import validator from 'validator';

import { Avatar } from '../../components/Avatar';
import Spinner from '../../assets/svgs/Spinner';
import Button from '../../components/Button';
import { Checkbox } from '../../components/Input/Checkbox';
import { InputHeader } from '../../components/Input/InputHeader';
import { InputText } from '../../components/Input/InputText';
import { Popup } from '../../components/Popup';
import { FlexPosition } from '../../enums/flex-position.enum';
import { PersonEnum } from '../../enums/person.enum';
import {
  CheckboxFields,
  InputTextareaFields,
  InputTextFields,
  MultipleInputTextareaFields,
  RangeSliderFields,
  SelectionButtons,
} from '../../enums/form-elements.enum';
import { NavigationRoute } from '../../enums/navigation.enum';
import { TextAlign } from '../../enums/text-align.enum';
import { RangeSliderField, SingleInputField } from '../../interfaces/Form.interface';

import { Store } from '../../interfaces/Store.interface';
import {
  SetSingleInputFieldValuePayload,
  ToggleCheckboxPayload,
  UserActionType,
} from '../../store/actions/user.action';

import Layout from '../../styles/Layout';
import { colors, fonts } from '../../styles/Theme';
import Typography from '../../styles/Typography';

const mapState = (state: Store) => ({
  singleInputFields: state.user.singleInputFields,
  multipleInputFields: state.user.multipleInputFields,
  checkboxFields: state.user.checkboxFields,
  selectedVacancy: state.user.selectedVacancy,
  selectionButtons: state.user.selectionButtons,
  rangeSliderFields: state.user.rangeSliderFields,
});

const mapDispatch = {
  setInputValue: (payload: SetSingleInputFieldValuePayload) => ({
    type: UserActionType.SET_INPUT_FIELD_VALUE,
    payload,
  }),
  toggleCheckbox: (payload: ToggleCheckboxPayload) => ({
    type: UserActionType.TOGGLE_CHECKBOX,
    payload,
  }),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const YourContactDetails: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
  const history = useHistory();

  const [isErrorPopupVisible, setIsErrorPopupVisible] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isDataProtectionPopupVisible, setIsDataProtectionPopupVisible] = React.useState(false);
  const [isRequestPending, setIsRequestPending] = React.useState(false);
  const [emailFieldHasError, setEmailFieldHasError] = React.useState(false);

  const firstNameValue = props.singleInputFields.find(item => item.key === InputTextFields.FIRST_NAME)?.value;
  const lastNameValue = props.singleInputFields.find(item => item.key === InputTextFields.LAST_NAME)?.value;
  const phoneNumberValue = props.singleInputFields.find(item => item.key === InputTextFields.PHONE_NUMBER)?.value;
  const emailValue = props.singleInputFields.find(item => item.key === InputTextFields.EMAIL)?.value;
  const otherContactOptionValue = props.singleInputFields.find(
    item => item.key === InputTextFields.OTHER_CONTACT_OPTION,
  )?.value;
  const isDataProtectionChecked = !!props.checkboxFields.find(item => item.key === CheckboxFields.DATA_PROTECTION)
    ?.checked;
  const selectedVacancy = props.selectedVacancy;

  const currentEmploymentValue = props.singleInputFields.find(
    (item: SingleInputField) => item.key === InputTextFields.CURRENT_EMPLOYMENT,
  )?.value;
  const experienceInYearsSelectedValue = props.selectionButtons.find(
    item => item.key === SelectionButtons.EXPERIENCE_IN_YEARS,
  )?.value;
  const personalGoalsValues = props.multipleInputFields.find(
    item => item.key === MultipleInputTextareaFields.PERSONA_GOALS,
  )?.values;
  const changeOfResidenceSelectedValue = props.selectionButtons.find(
    item => item.key === SelectionButtons.CHANGE_OF_RESIDENCE,
  )?.value;
  const newJobParticularlyImportantValue = props.singleInputFields.find(
    item => item.key === InputTextareaFields.NEW_JOB_PARTICULARLY_IMPORTANT,
  )?.value;

  const yourSalaryExpectations = props.rangeSliderFields.find(
    item => item.key === RangeSliderFields.YOUR_SALARY_EXPECTATIONS,
  ) as RangeSliderField;

  const convertNumberToCurrency = (number: number) => {
    return new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(number) + ' €';
  };

  const mapVacancydToJobPositionId = () => {
    switch (selectedVacancy) {
      case 'Scrum Master': {
        return '304686';
      }
      case 'Full Stack Developer': {
        return '44684';
      }
      case 'UI/UX Designer': {
        return '82845';
      }
      case 'iOS & Android Developer': {
        return '44693';
      }
      default: {
        return '45155';
      }
    }
  };

  const buildMessage = () => {
    let message = '';

    message += '++++++ Eine Neue Bewerbung hat uns über Next-Level-Recruiting erreicht ++++++';
    message += '\n\n';

    if (currentEmploymentValue) {
      message += 'Aktuell bin ich beschäftigt als: ' + currentEmploymentValue + buildMessageDivider();
    }
    if (experienceInYearsSelectedValue) {
      message +=
        'Wie viele Jahre Berufserfahrung bringst du mit?: ' + experienceInYearsSelectedValue + buildMessageDivider();
    }
    if (personalGoalsValues) {
      message +=
        'Sag uns ein paar deiner persönlichen oder beruflichen Ziele:\n\n- ' +
        personalGoalsValues.join('\n\n - ') +
        buildMessageDivider();
    }
    if (changeOfResidenceSelectedValue) {
      message +=
        'Du wohnst in Düsseldorf oder Umgebung oder hast die Bereitschaft in Düsseldorf zu arbeiten: ' +
        changeOfResidenceSelectedValue +
        buildMessageDivider();
    }
    if (newJobParticularlyImportantValue) {
      message +=
        'Was ist dir bei deiner neuen Tätigkeit besonders wichtig?: ' +
        newJobParticularlyImportantValue +
        buildMessageDivider();
    }
    if (yourSalaryExpectations) {
      message +=
        'Deine Gehaltsvorstellung (€/Jahr): ' +
        convertNumberToCurrency(yourSalaryExpectations.values[0]) +
        ' bis ' +
        convertNumberToCurrency(yourSalaryExpectations.values[1]);
    }

    return message;
  };

  const buildMessageDivider = () => {
    return '\n-------------------------------------------------------\n\n';
  };

  console.log(validator.isEmail(emailValue as string));

  const isNextButtonDisabled =
    firstNameValue === '' ||
    lastNameValue === '' ||
    phoneNumberValue === '' ||
    emailValue === '' ||
    emailFieldHasError ||
    !isDataProtectionChecked;

  const marginProps = {
    marginTop: 24,
    marginBottom: 24,
  };

  const popupTextProps = {
    color: colors.primary.nanoBlue,
    fontSize: 12,
    lineHeight: 16,
    marginTop: 16,
  };

  const sendApplication = async () => {
    if (!isNextButtonDisabled) {
      setIsRequestPending(true);
      const formData = new FormData();

      formData.append('company_id', '6614');
      formData.append('access_token', '7e16e7b230e5661d8dee');
      formData.append('job_position_id', mapVacancydToJobPositionId());

      firstNameValue && formData.append('first_name', firstNameValue);
      lastNameValue && formData.append('last_name', lastNameValue);
      emailValue && formData.append('email', emailValue);
      phoneNumberValue && formData.append('phone', phoneNumberValue);
      formData.append('message', buildMessage());

      try {
        await axios.post('https://api.personio.de/recruiting/applicant', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        history.push(NavigationRoute.SUCCESS);
        setIsRequestPending(false);
      } catch (error) {
        if (error.response.data.success === false) {
          setErrorMessage(error.response.data.error.message);
        } else {
          setErrorMessage(error.response.data.error);
        }

        setIsErrorPopupVisible(true);
        setIsRequestPending(false);
      }
    }
  };

  const ButtonContent = (
    <>
      Bewerbung absenden{' '}
      <IconContainer>
        {isRequestPending ? (
          <>
            {'     '}
            <Spinner />
          </>
        ) : (
          <>🚀</>
        )}
      </IconContainer>
    </>
  );

  return (
    <>
      <Typography.NanoSubTitle fontSize={42} lineHeight={42} marginTop={32}>
        WOW! Wir möchten dich kennenlernen!
      </Typography.NanoSubTitle>

      <Typography.NanoText marginTop={11} lineHeight={26}>
        Lass uns doch einfach mal unverbindlich quatschen! Wie können wir dich am besten erreichen?
      </Typography.NanoText>

      <Layout.Wrapper {...marginProps}>
        <InputHeader isLabel={true} isRequired={true}>
          Dein Vorname:
        </InputHeader>
        <InputText value={firstNameValue} changeEvent={props.setInputValue} type={InputTextFields.FIRST_NAME} />
      </Layout.Wrapper>

      <Layout.Wrapper {...marginProps}>
        <InputHeader isLabel={true} isRequired={true}>
          Dein Nachname:
        </InputHeader>
        <InputText value={lastNameValue} changeEvent={props.setInputValue} type={InputTextFields.LAST_NAME} />
      </Layout.Wrapper>

      <Layout.Wrapper {...marginProps}>
        <InputHeader isLabel={true} isRequired={true}>
          Deine Telefonnummer:
        </InputHeader>
        <InputText value={phoneNumberValue} changeEvent={props.setInputValue} type={InputTextFields.PHONE_NUMBER} />
      </Layout.Wrapper>

      <Layout.Wrapper {...marginProps}>
        <InputHeader isError={emailFieldHasError} isLabel={true} isRequired={true}>
          Deine E-Mail Adresse:
        </InputHeader>
        <InputText
          value={emailValue}
          changeEvent={props.setInputValue}
          blurEvent={() => setEmailFieldHasError(!validator.isEmail(emailValue as string))}
          type={InputTextFields.EMAIL}
          isError={emailFieldHasError}
        />
      </Layout.Wrapper>

      <Layout.Wrapper {...marginProps}>
        <InputHeader isLabel={true}>Wie wir dich sonst noch erreichen können:</InputHeader>
        <InputText
          value={otherContactOptionValue}
          changeEvent={props.setInputValue}
          type={InputTextFields.OTHER_CONTACT_OPTION}
        />
      </Layout.Wrapper>

      <Layout.Wrapper {...marginProps}>
        <CheckboxGrid>
          <Checkbox
            type={CheckboxFields.DATA_PROTECTION}
            changeEvent={props.toggleCheckbox}
            isChecked={isDataProtectionChecked}
          />
          <Typography.NanoHeadingSGSB19>
            Ich stimme der{' '}
            <CheckboxLink onClick={() => setIsDataProtectionPopupVisible(true)} fontSize={12}>
              Datenschutzerklärung
            </CheckboxLink>{' '}
            zu.
          </Typography.NanoHeadingSGSB19>
        </CheckboxGrid>
      </Layout.Wrapper>

      <ButtonWrapper>
        <Button
          isDisabled={isNextButtonDisabled}
          textAlign={TextAlign.CENTER}
          label=""
          marginTop={16}
          additionalButtonContent={ButtonContent}
          alignSelf={FlexPosition.CENTER}
          onClick={sendApplication}
        />
      </ButtonWrapper>

      <Typography.NanoBigTextSGSB15 textAlign={TextAlign.CENTER} marginTop={32}>
        Wir freuen uns auf dich! <br /> Deine NanoGiants
      </Typography.NanoBigTextSGSB15>

      <PersonContainer>
        <Avatar person={PersonEnum.MICHELE} />
        <Avatar person={PersonEnum.MARIT} />
      </PersonContainer>

      <Popup
        title={'Datenschutzerklärung'}
        closePopup={() => setIsDataProtectionPopupVisible(false)}
        isVisible={isDataProtectionPopupVisible}
      >
        <Typography.NanoTextSGSB19 {...popupTextProps}>
          NanoGiants GmbH verpflichtet sich, Ihre Privatsphäre zu schützen und zu respektieren. Wir verwenden Ihre
          persönlichen Daten nur zur Verwaltung Ihres Kontos und zur Bereitstellung der von Ihnen angeforderten Produkte
          und Dienstleistungen. Von Zeit zu Zeit möchten wir Sie über unsere Produkte und Dienstleistungen sowie andere
          Inhalte, die für Sie von Interesse sein könnten, informieren. Wenn Sie damit einverstanden sind, dass wir Sie
          zu diesem Zweck kontaktieren, geben Sie bitte an, das Sie damit einverstanden sind die angegebenen Daten dafür
          zu nutzen.
        </Typography.NanoTextSGSB19>

        <Typography.NanoTextSGSB19 {...popupTextProps}>
          Sie stimmen zu, andere Benachrichtigungen von NanoGiants GmbH zu erhalten. Sie können diese Benachrichtigungen
          jederzeit abbestellen. Weitere Informationen zum Abbestellen, zu unseren Datenschutzverfahren und dazu, wie
          wir Ihre Privatsphäre schützen und respektieren, finden Sie in unserer{' '}
          <Typography.NanoLinkSGSB19
            target="_blank"
            fontSize={12}
            lineHeight={14}
            color={colors.primary.nanoBlue}
            href={'https://nanogiants.de/datenschutz.html'}
          >
            Datenschutzrichtlinie
          </Typography.NanoLinkSGSB19>
          .
        </Typography.NanoTextSGSB19>

        <Typography.NanoTextSGSB19 {...popupTextProps}>
          Indem Sie unten auf „Einsenden“ klicken, stimmen Sie zu, dass NanoGiants GmbH die oben angegebenen
          persönlichen Daten speichert und verarbeitet, um Ihnen die angeforderten Inhalte bereitzustellen.
        </Typography.NanoTextSGSB19>
      </Popup>

      <Popup title={'Fehler'} closePopup={() => setIsErrorPopupVisible(false)} isVisible={isErrorPopupVisible}>
        <Typography.NanoTextSGSB19 {...popupTextProps}>
          {errorMessage}
          <br />
          <br />
          Ups, hier ist etwas schief gelaufen. Schreib uns gerne eine E-Mail an{' '}
          <Typography.NanoLinkSGSB19
            target="_blank"
            fontSize={12}
            lineHeight={14}
            color={colors.primary.nanoBlue}
            href={'mailto:karriere@nanogiants.de'}
          >
            karriere@nanogiants.de
          </Typography.NanoLinkSGSB19>
          , sodass wir dich kontaktieren können.
        </Typography.NanoTextSGSB19>
      </Popup>
    </>
  );
};

const PersonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-evenly;
  min-width: 320px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CheckboxGrid = styled.div`
  display: grid;
  column-gap: 12px;
  grid-template-columns: 25px auto;
  align-content: center;
  align-items: center;
`;

const CheckboxLink = styled(Typography.NanoLink)`
  font-family: ${fonts.SharpGroteskSemiBold19};
`;

const IconContainer = styled.div`
  position: relative;
  float: right;

  svg {
    position: absolute;
    top: -10px;
    right: -18px;
    width: 50px;
    height: 50px;
  }
`;

export default connector(YourContactDetails);
