import { defaultState } from '../../constants/store.constants';
import { UserState } from '../../interfaces/Store.interface';
import {
  SetMultipleInputFieldValuePayload,
  SetRangeSliderValuesPayload,
  SetSelectionButtonValuePayload,
  SetSingleInputFieldValuePayload,
  ToggleCheckboxPayload,
  UserActions,
  UserActionType,
} from '../actions/user.action';

export const userReducer = (state = defaultState.user, action: UserActions): UserState => {
  switch (action.type) {
    case UserActionType.SET_SELECTION_BUTTON_VALUE: {
      return {
        ...state,
        selectionButtons: state.selectionButtons.map(button => {
          const payload = action.payload as SetSelectionButtonValuePayload;

          if (button.key === payload.key) {
            button.value = payload.selectedValue;
          }
          return button;
        }),
      };
    }
    case UserActionType.SET_INPUT_FIELD_VALUE: {
      return {
        ...state,
        singleInputFields: state.singleInputFields.map(field => {
          const payload = action.payload as SetSingleInputFieldValuePayload;

          if (field.key === payload.key) {
            field.value = payload.newValue;
          }
          return field;
        }),
      };
    }
    case UserActionType.SET_MULTIPLE_INPUT_FIELD_VALUE: {
      return {
        ...state,
        multipleInputFields: state.multipleInputFields.map(field => {
          const payload = action.payload as SetMultipleInputFieldValuePayload;

          if (field.key === payload.key) {
            field.values = payload.values;
          }
          return field;
        }),
      };
    }
    case UserActionType.SET_RANGE_SLIDER_VALUES: {
      return {
        ...state,
        rangeSliderFields: state.rangeSliderFields.map(field => {
          const payload = action.payload as SetRangeSliderValuesPayload;

          if (field.key === payload.key) {
            field.values = payload.values;
          }
          return field;
        }),
      };
    }
    case UserActionType.TOGGLE_CHECKBOX: {
      return {
        ...state,
        checkboxFields: state.checkboxFields.map(field => {
          const payload = action.payload as ToggleCheckboxPayload;

          if (field.key === payload.key) {
            field.checked = !field.checked;
          }

          return field;
        }),
      };
    }
    case UserActionType.SET_SELECTED_VACANCY: {
      if (!action.payload) {
        return state;
      }
      const payload = action.payload as string;
      return {
        ...state,
        selectedVacancy: payload,
      };
    }

    default:
      return state;
  }
};
