import React from 'react';
import styled from 'styled-components';
import { MultipleInputTextareaFields } from '../../enums/form-elements.enum';
import { SetMultipleInputFieldValuePayload } from '../../store/actions/user.action';
import Input, { InputProps } from '../../styles/Input';
import { AddButton } from '../AddButton';

export interface InputTextProps extends InputProps {
  placeholder?: string;
  values: string[] | undefined;
  minRows?: number;
  maxRows?: number;
  type: MultipleInputTextareaFields;
  maxLength?: number;
  changeEvent: (value: SetMultipleInputFieldValuePayload) => void;
}

export const MultipleInputTextarea: React.FC<InputTextProps> = (props: InputTextProps) => {
  const isMaxLengthReached = () => {
    if (!props.values) {
      return;
    }

    const maxLength = props.maxLength ? props.maxLength : 5;

    return props.values.length > maxLength - 1;
  };

  const onChangeInputField = (index: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!props.values) {
      return;
    }

    props.values[index] = e.target.value;

    props.changeEvent({
      key: props.type,
      values: props.values,
    });
  };

  const addField = () => {
    if (!props.values) {
      return;
    }

    if (!isMaxLengthReached()) {
      props.values[props.values.length] = '';

      props.changeEvent({
        key: props.type,
        values: props.values,
      });
    }
  };

  const InputFields = props.values?.map((value, i) => (
    <Input.InputTextareaField
      {...props}
      key={i}
      minRows={props.minRows}
      maxRows={props.maxRows}
      placeholder={props.placeholder}
      value={value}
      onChange={onChangeInputField(i)}
    />
  ));

  return (
    <InputContainer>
      {InputFields}

      {!isMaxLengthReached() && <AddButton clickEvent={addField} />}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  textarea {
    margin-bottom: 8px;
  }
`;
