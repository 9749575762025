import React from 'react';
import styled from 'styled-components';
import { LayoutProps } from '../../interfaces/LayoutProps';
import { Footer } from '../Footer';
import { Header } from './Header';

export const DefaultLayout: React.FC<LayoutProps> = (props: LayoutProps) => {
  return (
    <DefaultScene>
      {props.disableHeader ? null : <Header {...props} />}
      {props.content ?? null}
      <Footer />
    </DefaultScene>
  );
};

const DefaultScene = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 335px;
  max-width: 450px;
`;
