import React, { FunctionComponent } from 'react';

const LogoSmallIcon: FunctionComponent = () => (
  <svg viewBox="0 0 120 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M17.0792 21.9566V20.6468C17.0792 17.9057 18.5081 16.3975 20.906 16.3975C23.0633 16.3975 24.3358 17.5088 24.5343 19.7082V19.8273H23.2501V19.7059C23.117 18.277 22.3769 17.4948 20.906 17.4948C19.2389 17.4948 18.3377 18.6202 18.3377 20.7122V21.9029C18.3377 23.9156 19.2249 24.9732 20.906 24.9732C22.4026 24.9732 23.1567 24.1257 23.2898 22.6688V22.51H24.588V22.6828C24.4035 24.8682 23.1988 26.1383 20.8546 26.1383C18.4428 26.1406 17.0792 24.7374 17.0792 21.9566Z"
        fill="white"
      />
      <path
        d="M27.3267 25.9541H26.1616V15.8794H27.3267V19.8766C27.7376 18.8703 28.6108 18.1955 29.881 18.1955C31.6951 18.1955 32.594 19.3606 32.594 21.2004V25.9541H31.3753V21.4806C31.3753 20.1568 30.8196 19.2695 29.456 19.2695C28.1065 19.2695 27.3244 20.3015 27.3244 21.7981V25.9541H27.3267Z"
        fill="white"
      />
      <path
        d="M34.1304 22.5265V21.8914C34.1304 19.5216 35.599 18.1978 37.6513 18.1978C39.7292 18.1978 41.1722 19.5613 41.1722 21.8914V22.5265C41.1722 24.8169 39.783 26.1408 37.6513 26.1408C35.4145 26.1408 34.1304 24.8169 34.1304 22.5265ZM39.9277 22.4985V21.9031C39.9277 20.2477 39.0265 19.2554 37.6513 19.2554C36.3017 19.2554 35.3748 20.1964 35.3748 21.9031V22.4985C35.3748 24.1002 36.1967 25.0411 37.6513 25.0411C39.1082 25.0411 39.9277 24.1025 39.9277 22.4985Z"
        fill="white"
      />
      <path
        d="M42.5216 22.5265V21.8914C42.5216 19.5216 43.9902 18.1978 46.0425 18.1978C48.1205 18.1978 49.5634 19.5613 49.5634 21.8914V22.5265C49.5634 24.8169 48.1742 26.1408 46.0425 26.1408C43.8058 26.1408 42.5216 24.8169 42.5216 22.5265ZM48.3213 22.4985V21.9031C48.3213 20.2477 47.42 19.2554 46.0448 19.2554C44.6953 19.2554 43.7684 20.1964 43.7684 21.9031V22.4985C43.7684 24.1002 44.5903 25.0411 46.0448 25.0411C47.4994 25.0411 48.3213 24.1025 48.3213 22.4985Z"
        fill="white"
      />
      <path
        d="M50.5955 23.5842V23.4394H51.9053V23.5725C52.0244 24.6045 52.8065 25.0948 53.9576 25.0948C55.2557 25.0948 55.7577 24.5391 55.7577 23.8106V23.785C55.7577 22.8977 54.7257 22.753 53.6004 22.5545C52.3022 22.3304 50.8873 22.0245 50.8873 20.4625V20.4228C50.8873 19.2041 51.8002 18.1978 53.7194 18.1978C55.6387 18.1978 56.645 19.099 56.8037 20.5022V20.6213H55.5733V20.5162C55.4799 19.6547 54.8448 19.2064 53.7078 19.2064C52.503 19.2064 52.0921 19.7364 52.0921 20.3715V20.3971C52.0921 21.2447 53.1381 21.3894 54.2494 21.5879C55.5476 21.8261 56.9625 22.1296 56.9625 23.7056V23.7453C56.9625 25.1088 56.0099 26.1408 53.9319 26.1408C51.9076 26.1408 50.7682 25.2536 50.5955 23.5842Z"
        fill="white"
      />
      <path
        d="M61.6508 26.1408C59.3207 26.1408 58.1416 24.7516 58.1416 22.5405V21.9171C58.1416 19.6523 59.5051 18.1978 61.6905 18.1978C64.0067 18.1978 65.0923 19.8391 65.0923 21.7864V22.5802H59.3324C59.3464 24.1819 60.1659 25.0948 61.7022 25.0948C62.7739 25.0948 63.4766 24.6185 63.7008 23.7172V23.6379H64.999V23.7032C64.6557 25.2279 63.5047 26.1408 61.6508 26.1408ZM59.3464 21.5996H63.8876C63.8479 20.117 63.1054 19.2041 61.7022 19.2041C60.2873 19.2041 59.4257 20.089 59.3464 21.5996Z"
        fill="white"
      />
      <path
        d="M74.3848 25.9541H73.605C71.6974 25.9541 71.1698 25.319 71.1698 23.1617V19.482H69.7922V18.436C69.951 18.436 70.2942 18.436 70.4927 18.422C71.1021 18.408 71.1931 18.2889 71.2468 17.5348C71.2608 17.3106 71.2865 16.6872 71.2865 16.4888H72.2928V18.3963H74.3848V19.482H72.2928V23.0029C72.2928 24.5509 72.5963 24.817 73.8805 24.817H74.3825V25.9541H74.3848Z"
        fill="white"
      />
      <path
        d="M75.244 22.5265V21.8914C75.244 19.5216 76.7126 18.1978 78.7649 18.1978C80.8429 18.1978 82.2858 19.5613 82.2858 21.8914V22.5265C82.2858 24.8169 80.8966 26.1408 78.7649 26.1408C76.5282 26.1408 75.244 24.8169 75.244 22.5265ZM81.0437 22.4985V21.9031C81.0437 20.2477 80.1425 19.2554 78.7672 19.2554C77.4177 19.2554 76.4908 20.1964 76.4908 21.9031V22.4985C76.4908 24.1002 77.3127 25.0411 78.7672 25.0411C80.2218 25.0411 81.0437 24.1025 81.0437 22.4985Z"
        fill="white"
      />
      <path
        d="M93.9482 23.7311C93.6844 25.1086 92.8228 26.1406 91.0227 26.1406C88.8373 26.1406 87.5812 24.8168 87.5812 21.9847V20.6468C87.5812 17.8404 89.0101 16.3975 91.4196 16.3975C93.5256 16.3975 94.8098 17.5368 94.9405 19.5354V19.7082H93.6704V19.5354C93.5513 18.3307 92.8882 17.4972 91.4056 17.4972C89.6989 17.4972 88.8373 18.5688 88.8373 20.7005V21.945C88.8233 24.0626 89.6989 25.0176 91.3402 25.0176C93.075 25.0176 93.6961 23.7591 93.6961 22.4353V22.2742L91.2072 22.2602V21.2002H94.9545V25.9538H94.0533L93.9482 23.7311Z"
        fill="white"
      />
      <path
        d="M96.8083 25.9543V18.3965H97.789L97.9477 20.1966C98.2396 18.9662 99.0334 18.3428 100.516 18.3428H100.939V19.5872H100.355C98.5408 19.5872 97.9991 20.3951 97.9991 22.2209V25.9543H96.8083Z"
        fill="white"
      />
      <path
        d="M101.639 22.5265V21.8914C101.639 19.5216 103.108 18.1978 105.16 18.1978C107.238 18.1978 108.681 19.5613 108.681 21.8914V22.5265C108.681 24.8169 107.292 26.1408 105.16 26.1408C102.923 26.1408 101.639 24.8169 101.639 22.5265ZM107.439 22.4985V21.9031C107.439 20.2477 106.538 19.2554 105.162 19.2554C103.813 19.2554 102.886 20.1964 102.886 21.9031V22.4985C102.886 24.1002 103.708 25.0411 105.162 25.0411C106.617 25.0411 107.439 24.1025 107.439 22.4985Z"
        fill="white"
      />
      <path
        d="M114.693 20.3017L112.575 25.9543H111.45L109.503 18.3965H110.708L112.164 24.3152L114.31 18.3965H115.132L117.105 24.3012L118.826 18.3965H120.005L117.754 25.9566H116.654L114.693 20.3017Z"
        fill="white"
      />
      <path
        d="M17.0792 12.7272V3.28993H18.7346L22.7715 9.66864L23.0237 10.2897L22.97 9.61494V3.2876H24.6113V12.7249H23.0096L18.9191 6.26682L18.6552 5.64576L18.7206 6.30651V12.7249H17.0792V12.7272Z"
        fill="white"
      />
      <path
        d="M28.4358 12.9255C26.7804 12.9255 25.9469 12.1317 25.9469 10.8873C25.9469 10.0397 26.3181 9.40465 26.9392 9.02174C27.1517 8.87698 27.3758 8.76958 27.5883 8.70421C28.0389 8.54544 28.6063 8.44037 29.5612 8.29328C30.9247 8.12051 31.4664 7.93605 31.4664 7.31266C31.4664 6.67759 31.0555 6.28067 29.8647 6.28067C28.6063 6.28067 28.0389 6.82235 27.9852 7.80297H26.1991C26.3181 6.06821 27.5883 4.93115 29.7737 4.93115C30.6072 4.93115 31.2423 5.03622 31.7466 5.2627C32.7389 5.6853 33.1358 6.49314 33.1358 7.59284V12.7294H31.7326L31.5481 11.2071C31.0321 12.1971 30.0118 12.9255 28.4358 12.9255ZM30.3293 11.2445C31.0695 10.7822 31.4804 10.014 31.4804 9.28558V8.87465C31.1629 9.09879 30.6329 9.25756 29.9441 9.36496C29.0172 9.50972 28.6483 9.61712 28.2887 9.84126C27.9852 10.0397 27.8381 10.3036 27.8381 10.6351C27.8381 11.2702 28.235 11.5877 29.0289 11.5877C29.5355 11.5877 29.9581 11.4686 30.3293 11.2445Z"
        fill="white"
      />
      <path
        d="M34.7374 12.7271V5.1156H36.206L36.3648 6.6379C36.7757 5.5919 37.6349 4.93115 38.9471 4.93115C40.7869 4.93115 41.7652 6.14992 41.7652 8.04112V12.7271H40.0188V8.46372C40.0188 7.21927 39.6219 6.43944 38.3774 6.43944C37.0792 6.43944 36.4839 7.34068 36.4839 8.75557V12.7271H34.7374Z"
        fill="white"
      />
      <path
        d="M43.0377 9.07311V8.87465C43.0377 8.02711 43.2105 7.29865 43.5397 6.71728C44.2005 5.55221 45.3795 4.93115 46.8762 4.93115C47.6443 4.93115 48.2934 5.07591 48.8607 5.39344C50.0258 6.0145 50.7146 7.19358 50.7146 8.87465V9.07311C50.7146 9.90664 50.5558 10.5954 50.2383 11.1651C49.5775 12.3162 48.4241 12.9255 46.8762 12.9255C44.4129 12.9255 43.0377 11.562 43.0377 9.07311ZM48.9541 9.04743V8.87465C48.9541 7.31266 48.1463 6.41376 46.8902 6.41376C45.6457 6.41376 44.8122 7.26129 44.8122 8.87465V9.04743C44.8122 10.5557 45.5803 11.4033 46.8762 11.4033C48.186 11.4033 48.9541 10.5557 48.9541 9.04743Z"
        fill="white"
      />
      <path
        d="M57.8217 11.7211C57.3454 12.4495 56.5516 12.9258 55.3468 12.9258C53.0447 12.9258 51.7863 11.5623 51.7863 8.53172V7.61881C51.7863 4.5999 53.3482 3.11963 55.9165 3.11963C58.2724 3.11963 59.5962 4.37809 59.7013 6.56114H57.8871C57.8731 6.20392 57.7937 5.91206 57.649 5.60854C57.3851 5.03884 56.8668 4.66994 55.8885 4.66994C54.2868 4.66994 53.5584 5.65056 53.5584 7.68886V8.46868C53.5444 10.507 54.2728 11.3662 55.8348 11.3662C56.3391 11.3662 56.722 11.2728 57.0256 11.0743C57.635 10.6914 57.8988 10.0027 57.9128 9.27419H55.6107V7.77524H59.741V12.725H58.4311L58.298 10.4883C58.207 10.9272 58.0459 11.3498 57.8217 11.7211Z"
        fill="white"
      />
      <path
        d="M61.0928 3.14281C61.0928 2.29527 61.4757 2.00342 62.2438 2.00342C63.012 2.00342 63.3949 2.29527 63.3949 3.14281C63.3949 3.96233 63.0237 4.29387 62.2438 4.29387C61.4103 4.29387 61.0928 4.01603 61.0928 3.14281ZM61.3426 5.11572H63.103V12.7272H61.3426V5.11572Z"
        fill="white"
      />
      <path
        d="M66.8224 12.9255C65.167 12.9255 64.3335 12.1317 64.3335 10.8873C64.3335 10.0397 64.7047 9.40465 65.3258 9.02174C65.5383 8.87698 65.7624 8.76958 65.9749 8.70421C66.4255 8.54544 66.9928 8.44037 67.9478 8.29328C69.3113 8.12051 69.853 7.93605 69.853 7.31266C69.853 6.67759 69.4421 6.28067 68.2513 6.28067C66.9928 6.28067 66.4255 6.82235 66.3718 7.80297H64.5857C64.7047 6.06821 65.9749 4.93115 68.1602 4.93115C68.9938 4.93115 69.6288 5.03622 70.1332 5.2627C71.1255 5.6853 71.5224 6.49314 71.5224 7.59284V12.7294H70.1192L69.9347 11.2071C69.4164 12.1971 68.3984 12.9255 66.8224 12.9255ZM68.7159 11.2445C69.4584 10.7822 69.867 10.014 69.867 9.28558V8.87465C69.5495 9.09879 69.0195 9.25756 68.3307 9.36496C67.4038 9.50972 67.0349 9.61712 66.6776 9.84126C66.3741 10.0397 66.227 10.3036 66.227 10.6351C66.227 11.2702 66.6239 11.5877 67.4178 11.5877C67.9221 11.5877 68.3447 11.4686 68.7159 11.2445Z"
        fill="white"
      />
      <path
        d="M73.124 12.7271V5.1156H74.5926L74.7514 6.6379C75.1623 5.5919 76.0215 4.93115 77.3313 4.93115C79.1712 4.93115 80.1495 6.14992 80.1495 8.04112V12.7271H78.403V8.46372C78.403 7.21927 78.0061 6.43944 76.7617 6.43944C75.4658 6.43944 74.8681 7.34068 74.8681 8.75557V12.7271H73.124Z"
        fill="white"
      />
      <path
        d="M84.9171 12.7273C84.2027 12.7273 83.647 12.6222 83.2758 12.4494C82.5076 12.0665 82.2695 11.2306 82.2695 9.68267V6.64975H80.7869V5.18115H81.4616C81.7138 5.18115 81.8842 5.15547 82.0056 5.10177C82.2578 4.98269 82.3372 4.69084 82.3769 4.06978C82.3909 3.73824 82.4025 3.28995 82.4025 3.06348H83.8852V5.11578H85.8978V6.65208H83.8852V9.39082C83.8852 9.8928 83.9248 10.264 83.9902 10.5162C84.1093 11.0182 84.5062 11.1116 85.3538 11.1116H85.8954V12.7273H84.9171Z"
        fill="white"
      />
      <path
        d="M86.5072 10.2101H88.3867C88.5455 11.1113 89.1665 11.4685 90.2522 11.4685C91.3636 11.4685 91.8002 11.0973 91.8002 10.4879C91.8002 10.2638 91.7068 10.077 91.5084 9.9579C91.1255 9.73375 90.4624 9.64036 89.7339 9.53529C89.0451 9.43023 88.3447 9.31115 87.775 9.00529C87.2053 8.69943 86.8481 8.18577 86.8481 7.33824C86.8481 5.93501 87.8941 4.92871 89.9861 4.92871C92.1691 4.92871 93.1614 5.8673 93.3739 7.3896H91.5994C91.4803 6.64947 90.9784 6.31792 89.9721 6.31792C88.8864 6.31792 88.5548 6.70083 88.5548 7.23083C88.5548 7.45498 88.6482 7.64176 88.8467 7.76084C89.2296 7.98498 89.9067 8.07837 90.6328 8.18344C91.3216 8.2885 92.022 8.40758 92.5917 8.71344C93.1614 9.01697 93.5186 9.53296 93.5186 10.3805C93.5186 11.8491 92.4586 12.9208 90.2102 12.9208C87.8707 12.9254 86.7313 11.9845 86.5072 10.2101Z"
        fill="white"
      />
      <path
        d="M2.61966 0V3.84543H8.54074C8.50572 5.71795 7.82629 7.48541 6.26197 8.47303C5.4798 8.98436 4.49218 9.22251 3.19869 9.22251C1.91688 9.22251 0.852206 8.99603 0 8.51739V13.0936C0.611721 13.187 1.25613 13.236 1.94023 13.236C5.03619 13.236 7.07915 12.0103 8.30259 10.1401C8.88163 9.18748 9.29022 8.09946 9.52837 6.97642L9.86925 12.7271H13.2384V0H2.61966Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="120" height="26.1406" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoSmallIcon;
