import React from 'react';
import styled from 'styled-components';
import { DefaultLayout } from '../../components/Layout/DefaultLayout';
import Layout from '../../styles/Layout';
import Typography from '../../styles/Typography';
import NeinToFive from '../../assets/svgs/NeinToFive';
import Teamspirit from '../../assets/svgs/Teamspirit';
import BigGoals from '../../assets/svgs/BigGoald';
import Growth from '../../assets/svgs/Growth';
import { PersonEnum } from '../../enums/person.enum';
import { TextAlign } from '../../enums/text-align.enum';
import { colors } from '../../styles/Theme';
import SocialLinks from '../../components/SocialLinks';
import NanoGiantsPeople from '../../assets/images/nanogiants.png';
import TestimonialList, { Testimonial } from './components/TestimonialList';
import { CompanyMissionItem, CompanyMissionList } from './components/CompanyMissionList';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';

type CompanyMissionProps = {
  title?: string;
};

export const CompanyMission: React.FC<CompanyMissionProps> = (props: CompanyMissionProps) => {
  const history = useHistory();

  const onBackClick = () => {
    history.goBack();
  };

  const companyMissionData: CompanyMissionItem[] = [
    {
      icon: <Growth />,
      title: 'Wachstum ab Tag 1',
      text:
        'Wir befähigen dich durch individuelle Coachings und kostenloser Literatur über dich hinaus zu wachsen und das Beste aus dir heraus zu holen.',
    },
    {
      icon: <BigGoals />,
      title: 'Große Ziele',
      text:
        'Die NanoGiants wissen genau, wo wir zusammen in einem, drei und zehn Jahren stehen wollen. Daran arbeiten wir alle gemeinsam Quartal für Quartal und Jahr für Jahr.',
    },
    {
      icon: <NeinToFive />,
      title: 'Nein to five ',
      text:
        'Wer bei uns arbeitet, ist gleichzeitig Smooth Operator. Flexible Arbeitszeiten sowie flexibler Arbeitsort für alle. Du sorgst selbst dafür, dass es klappt.',
    },
    {
      icon: <Teamspirit />,
      title: 'Smells like Teamspirit',
      text:
        'Wir finden, dass man zusammen mehr schafft und es mehr Spaß macht. Deshalb arbeiten bei uns nur Menschen, denen du auch gerne am Wochenende begegnen möchtest.',
    },
  ];

  const testimonials: Testimonial[] = [
    {
      person: PersonEnum.JAN,
      personName: 'Jan-Erik Lorfeo',
      text:
        'Ich bin bei den NanoGiants, weil ich hier dank der persönlichen Coachings besonders befähigt werde, mich persönlich und beruflich weiterzuentwickeln.',
    },
    {
      person: PersonEnum.CONSTANTIN,
      personName: 'Constantin Dießenbacher',
      text:
        'Ich bin bei den NanoGiants, weil ich hier die Möglichkeit habe rasant zu wachsen, man Dinge proaktiv vorantreiben kann und intrinsische Motivation Vorrang hat.',
    },
    {
      person: PersonEnum.DENNIS,
      personName: 'Dennis Matthias Scherf',
      text:
        'Ich bin bei den NanoGiants, weil jeder Tag neue Herausforderungen bereithält und ich neue Ideen einbringen kann.',
    },
    {
      person: PersonEnum.NADJA,
      personName: 'Nadja Niesner',
      text:
        'Ich bin bei den NanoGiants, weil ich die Möglichkeit habe Wege einzuschlagen, die ich vor einem oder zwei Jahren nie erdacht hätte.',
    },
  ];

  const CompanyMissionContent = (
    <Layout.DefaultSceneWrapper>
      {props.title}

      <Typography.NanoTitle fontSize={79}>Choose to grow</Typography.NanoTitle>

      <NanoGiantsImage src={NanoGiantsPeople} />
      <Typography.NanoHeadingSGSB15 fontSize={32} marginTop={38}>
        Unsere Mission
      </Typography.NanoHeadingSGSB15>
      <Typography.NanoHeadingSGSB19 textAlign={TextAlign.CENTER} fontSize={16}>
        “We create a world where <NanoRed>technology</NanoRed>
        <br /> helps everyone to <NanoRed>grow</NanoRed>”
      </Typography.NanoHeadingSGSB19>
      <CompanyMissionList companyMissions={companyMissionData} />

      <TestimonialList testimonialList={testimonials} />
      <Button marginTop={40} label={'Zurück'} onClick={onBackClick} />

      <SocialLinks marginTop={60} />
    </Layout.DefaultSceneWrapper>
  );

  return <DefaultLayout content={CompanyMissionContent} showBackButton={true}></DefaultLayout>;
};

const NanoGiantsImage = styled.img`
  max-width: 335px;
  margin-top: 35px;
`;

const NanoRed = styled.span`
  color: ${colors.primary.giantRed};
`;
