export enum SelectionButtons {
  EXPERIENCE_IN_YEARS = 'EXPERIENCE_IN_YEARS',
  CHANGE_OF_RESIDENCE = 'CHANGE_OF_RESIDENCE',
}

export enum InputTextFields {
  CURRENT_EMPLOYMENT = 'CURRENT_EMPLOYMENT',
  PERSONA_GOALS = 'PERSONA_GOALS',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
  OTHER_CONTACT_OPTION = 'OTHER_CONTACT_OPTION',
}

export enum InputTextareaFields {
  NEW_JOB_PARTICULARLY_IMPORTANT = 'NEW_JOB_PARTICULARLY_IMPORTANT',
}

export enum MultipleInputTextareaFields {
  PERSONA_GOALS = 'PERSONA_GOALS',
}

export enum CheckboxFields {
  DATA_PROTECTION = 'DATA_PROTECTION',
}

export enum RangeSliderFields {
  YOUR_SALARY_EXPECTATIONS = 'YOUR_SALARY_EXPECTATIONS',
}

export enum MultipleInputTextFields {}

export const CombinedSingleInputFields = { ...InputTextareaFields, ...InputTextFields };
export const CombinedMultipleInputFields = { ...MultipleInputTextareaFields, ...MultipleInputTextFields };
