import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { WrapperProps } from './Layout';
import { colors, fonts } from './Theme';

export interface InputProps extends WrapperProps {
  isError?: boolean;
}

export default {
  Description: styled.h4`
    position: relative;
    white-space: pre-line;
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    font-family: ${fonts.SharpGroteskSemiBold19};
    line-height: 26px;
    color: ${colors.basic.WHITE};
  `,
  Wrapper: styled.div<InputProps>`
    position: relative;
    margin-left: ${props => props.marginLeft ?? 0}px;
    margin-top: ${props => props.marginTop ?? 26}px;
    margin-right: ${props => props.marginRight ?? 0}px;
    margin-bottom: ${props => props.marginBottom ?? 32}px;
  `,
  Header: styled.div`
    padding-bottom: 10px;
    display: grid;
    grid-area: header;
    grid-template-areas: 'label annotation';
  `,
  Label: styled.label<InputProps>`
    display: grid;
    align-items: center;
    grid-area: label;
    padding-bottom: 3px;
    font-size: 12px;
    line-height: 16px;
    font-family: ${fonts.SharpGroteskSemiBold19};
    color: ${props => (props.isError ? colors.primary.giantRed : colors.basic.WHITE)};
    text-align: left;
  `,
  Annotation: styled.label`
    display: grid;
    align-items: end;
    grid-area: annotation;
    font-size: 10px;
    line-height: 14px;
    font-family: ${fonts.SharpGroteskSemiBold19};
    color: ${colors.secondary.white50};
    text-align: right;
  `,
  InputTextField: styled.input<InputProps>`
    grid-area: field;
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => (props.isError ? colors.primary.giantRed : colors.basic.WHITE)};
    border-radius: 4px;
    background: transparent;

    color: ${colors.basic.WHITE};
    font-size: 16px;
    font-weight: 400;
    font-family: ${fonts.SharpGrotesk19};
    line-height: 26px;

    :focus {
      outline: none;
      border-color: ${colors.basic.WHITE};
    }
    ::placeholder {
      color: ${colors.secondary.white50};
      opacity: 1;
    }
  `,
  InputTextareaField: styled(TextareaAutosize)<InputProps>`
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => (props.isError ? colors.primary.giantRed : colors.basic.WHITE)};
    border-radius: 4px;
    background: transparent;

    color: ${colors.basic.WHITE};
    font-size: 16px;
    font-weight: 400;
    font-family: ${fonts.SharpGrotesk19};
    line-height: 26px;

    :focus {
      outline: none;
      border-color: ${colors.basic.WHITE};
    }
    ::placeholder {
      color: ${colors.secondary.white50};
      opacity: 1;
    }
  `,
};
