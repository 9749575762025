import React from 'react';
import Input, { InputProps } from '../../styles/Input';
import { SetSingleInputFieldValuePayload } from '../../store/actions/user.action';
import { InputTextareaFields } from '../../enums/form-elements.enum';

export interface InputTextareaProps extends InputProps {
  placeholder?: string;
  value?: string;
  minRows?: number;
  maxRows?: number;
  type: InputTextareaFields;
  changeEvent: (value: SetSingleInputFieldValuePayload) => void;
}

export const InputTextarea: React.FC<InputTextareaProps> = (props: InputTextareaProps) => {
  const onChangeInputField = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.changeEvent({
      key: props.type,
      newValue: e.target.value,
    });
  };

  return (
    <Input.InputTextareaField
      {...props}
      minRows={props.minRows}
      placeholder={props.placeholder}
      value={props.value}
      onChange={onChangeInputField}
    />
  );
};
