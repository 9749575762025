import React from 'react';
import { InputTextFields } from '../../enums/form-elements.enum';
import { SetSingleInputFieldValuePayload } from '../../store/actions/user.action';
import Input, { InputProps } from '../../styles/Input';

export interface InputTextProps extends InputProps {
  value: string | undefined;
  placeholder?: string;
  type: InputTextFields;
  changeEvent: (value: SetSingleInputFieldValuePayload) => void;
  blurEvent?: () => void;
}

export const InputText: React.FC<InputTextProps> = (props: InputTextProps) => {
  const onChangeInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.changeEvent({
      key: props.type,
      newValue: e.target.value,
    });
  };

  return (
    <Input.InputTextField
      {...props}
      placeholder={props.placeholder}
      value={props.value}
      onChange={onChangeInputField}
      onBlur={props.blurEvent}
    />
  );
};
