import React from 'react';
import styled from 'styled-components';
import PlusIcon from '../assets/svgs/PlusIcon';
import { colors, fonts } from '../styles/Theme';

interface AddButtonProps {
  clickEvent: () => void;
}

export const AddButton: React.FC<AddButtonProps> = (props: AddButtonProps) => {
  return (
    <AddButtonWrapper {...props} onClick={props.clickEvent}>
      <PlusIcon />
    </AddButtonWrapper>
  );
};

const AddButtonWrapper = styled.div<AddButtonProps>`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 18px 30px;
  border: 1px solid ${colors.basic.WHITE};

  border-radius: 4px;
  background-color: ${colors.primary.nanoBlueLight};
  align-items: center;
  justify-content: center;

  color: ${colors.basic.WHITE};
  font-family: ${fonts.SharpGroteskSemiBold19};
  cursor: pointer;

  transition: background 0.2s ease-in;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${colors.basic.WHITE};

      svg path {
        transition: fill 0.2s ease-in;
        fill: ${colors.primary.nanoBlue};
      }
    }
  }
`;
