import { createGlobalStyle } from 'styled-components';
import { colors } from './Theme';

export const GlobalStyle = createGlobalStyle`
[draggable] {
  outline: 1px solid transparent
}
.slide-router-enter-done  {
  position: static !important;
}
/* back */
.back-enter {
  transform: translateX(-100%);
}
.back-enter-active {
  transform: translateX(0);
}
.back-exit {
  transform: translateX(0);
}
.back-exit-active {
  transform: translate(100%);
}

/* next */
.next-enter {
  transform: translateX(100%);
}
.next-enter-active {
  transform: translateX(0);
}
.next-exit {
  transform: translateX(0);
}
.next-exit-active {
  transform: translateX(-100%);
}

.rocket__fire span, .rocket__fire, .rocket__fire-hose span, .rocket__fire-hose, .rocket__wings span, .rocket__wings, .rocket__body, .rocket__inner, .rocket__wrapper {
  position: absolute;
}

.rocket__fire span:nth-child(5), .rocket__fire span:nth-child(4), .rocket__fire span:nth-child(3), .rocket__fire span:nth-child(1) {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff7332+70,f7e754+98 */
  background: #ff7332;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ff7332 70%, #f7e754 98%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ff7332 70%, #f7e754 98%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ff7332 70%, #f7e754 98%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ff7332", endColorstr="#f7e754",GradientType=0 );
  /* IE6-9 */
}

.rocket__fire span:nth-child(2) {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff7332+50,f7e754+98 */
  background: #ff7332;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ff7332 50%, #f7e754 98%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ff7332 50%, #f7e754 98%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ff7332 50%, #f7e754 98%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ff7332", endColorstr="#f7e754",GradientType=0 );
  /* IE6-9 */
}

.container {
  background-color: #000;
  width: 100%;
  position: relative;
  height: 100%;
  border: 1px solid silver;
  margin: 0 auto;
  overflow: hidden;
}

@keyframes vibrate-1 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-0.1rem, 0, 0);
  }
  100% {
    transform: translate3d(0.1rem, 0, 0);
  }
}
@keyframes vibrate-2 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-0.3rem, 0, 0);
  }
  100% {
    transform: translate3d(0.3rem, 0, 0);
  }
}
@keyframes takeoff {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0deg) scale(1);
  }
  20% {
    transform: translate3d(0, -30%, 0) rotateX(0deg) scale(1);
  }
  100% {
    transform: translate3d(0, -300%, 0) rotateX(30deg) scale(0.25);
  }
}
.rocket__wrapper {
  height: 30rem;
  width: 10rem;
  left: 50%;
  bottom: 0;
  margin: 0 0 0 -5rem;
  perspective: 200px;
  transform: scale(0.5, 0.5)
}
.play .rocket__wrapper {
  animation: takeoff 8s 1s linear forwards;
}
.rocket__inner {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.play .rocket__inner {
  animation: vibrate-1 0.1s 0s 50 linear, vibrate-2 0.1s 0.5s 150 linear, vibrate-1 0.1s 4s infinite linear;
}
.rocket__body {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 2;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6db3f2+0,54a3ee+50,3690f0+51,1e69de+100;Blue+Gloss+%233 */
  background: #6db3f2;
  /* Old browsers */
  background: -moz-linear-gradient(left, #6db3f2 0%, #54a3ee 50%, #3690f0 51%, #1e69de 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #6db3f2 0%, #54a3ee 50%, #3690f0 51%, #1e69de 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #6db3f2 0%, #54a3ee 50%, #3690f0 51%, #1e69de 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#6db3f2", endColorstr="#1e69de",GradientType=1 );
  /* IE6-9 */
}
.rocket__wings {
  z-index: 1;
  top: 70%;
  width: 100%;
  left: 0;
}
.rocket__wings span {
  content: "";
  width: 0;
  height: 0;
  border: 4rem solid transparent;
  left: 50%;
  bottom: 1%;
  border-bottom-width: 13rem;
}
.rocket__wings span:nth-child(1) {
  margin: 0 0 0 -6rem;
  transform: rotate(10deg);
  border-bottom-color: #6db3f2;
  z-index: 0;
}
.rocket__wings span:nth-child(2) {
  margin: 0 0 0 -2rem;
  transform: rotate(-10deg);
  z-index: 0;
  border-bottom-color: #1E69DE;
}
.rocket__fire-hose {
  z-index: 1;
  bottom: -1rem;
  width: 100%;
  left: 0;
}
.rocket__fire-hose span {
  content: "";
  width: 0;
  height: 0;
  border: 4rem solid transparent;
  left: 50%;
  bottom: 1%;
  border-bottom-width: 13rem;
  margin: 0 4rem 0 -4rem;
  border-bottom-color: #3e9bee;
}
.rocket__fire {
  height: 10rem;
  width: 5rem;
  left: 50%;
  bottom: -9rem;
  margin-left: -2.5rem;
  z-index: 0;
}
.rocket__fire span {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: fire-y 0.2s infinite forwards;
}
.rocket__fire span:nth-child(1) {
  animation-delay: 0s;
  margin-left: -1.5rem;
}
.rocket__fire span:nth-child(2) {
  animation-delay: 0.15s;
}
.rocket__fire span:nth-child(3) {
  animation-delay: 0.1s;
  margin-left: 1.5rem;
}
.rocket__fire span:nth-child(4) {
  animation-delay: 0.2s;
  margin-left: -0.5rem;
}
.rocket__fire span:nth-child(5) {
  animation-delay: 0.15s;
  margin-left: 0.5rem;
}

button {
  font-size: 1.8rem;
  margin: 1rem;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  color: #fff;
  font-weight: normal;
  text-transform: uppercase;
  background: #6db3f2;
  /* Old browsers */
  background: -moz-linear-gradient(left, #6db3f2 0%, #54a3ee 50%, #3690f0 51%, #1e69de 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #6db3f2 0%, #54a3ee 50%, #3690f0 51%, #1e69de 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #6db3f2 0%, #54a3ee 50%, #3690f0 51%, #1e69de 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#6db3f2", endColorstr="#1e69de",GradientType=1 );
  /* IE6-9 */
}
button:focus {
  outline: none;
}

@keyframes fire-y {
  0%, 100% {
    transform: translate3d(0, 0%, 0);
  }
  50% {
    transform: translate3d(0, -10%, 0);
  }
}
@keyframes fire-x {
  0%, 100% {
    transform: translate3d(0%, 0%, 0);
  }
  50% {
    transform: translate3d(-10%, -10%, 0);
  }
}
body{
    margin:0;
    background-color: ${colors.primary.nanoBlue};
}`;
