import { defaultState } from '../../constants/store.constants';
import { AppState } from '../../interfaces/Store.interface';
import { AppActions } from '../actions/app.action';

export const appReducer = (state = defaultState.app, action: AppActions): AppState => {
  switch (action.type) {
    case 'INCREMENT':
      return {
        ...state,
        count: state.count + 1,
      };
    case 'DECREMENT':
      return {
        ...state,
        count: state.count - 1,
      };
    default:
      return state;
  }
};
