import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import LogoSmallIcon from '../../assets/svgs/LogoSmallIcon';
import { LayoutProps } from '../../interfaces/LayoutProps';
import Typography from '../../styles/Typography';
export const Header: React.FC<LayoutProps> = (props: LayoutProps) => {
  const history = useHistory();

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <HeaderContainer>
      <LogoWrapper {...props}>
        {props?.showBackButton ? (
          <Typography.NanoLink onClick={onBackClick}>{props.backButtonLabel ?? 'Zurück'}</Typography.NanoLink>
        ) : (
          <div></div>
        )}
        <LogoContainer>
          <a rel="noreferrer" href="https://nanogiants.de/" title="Nanogiants" target="_blank">
            <LogoSmallIcon />
          </a>
        </LogoContainer>
      </LogoWrapper>
    </HeaderContainer>
  );
};

const LogoWrapper = styled.div`
  padding: 22px 16px 0px 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  flex-basis: 127px;
`;

const HeaderContainer = styled.div`
  width: 100%;
`;
