import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { NavigationRoute } from './enums/navigation.enum';
import './i18n/i18n';
import { CompanyMission } from './scenes/CompanyMission/CompanyMission';
import { Quiz, QuizPages } from './scenes/Quiz/Quiz';
import { Start } from './scenes/Start/Start';
import { Success } from './scenes/Success/Success';
import { VacancyDetail } from './scenes/VacancyDetail/VacancyDetail';
import GlobalFonts from './styles/Fonts';
import { GlobalStyle } from './styles/Style';
import SlideRoutes from 'react-slide-routes';
import ScrollToTop from './components/ScrollToTop';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { DataProtection } from './scenes/DataProtection/DataProtection';

function App(): JSX.Element {
  const location = useLocation();

  const { trackPageView, enableLinkTracking } = useMatomo();
  enableLinkTracking();
  // Track page view
  React.useEffect(() => {
    console.log(location);
    console.log(window.location);
    trackPageView({
      documentTitle: location.pathname, // optional
      href: window.location.href, // optional
    });
  });

  return (
    <AppWarpper>
      <GlobalFonts />
      <GlobalStyle />
      <ScrollToTop>
        <SlideRoutes location={location}>
          <Route exact={true} component={Start} path={NavigationRoute.ROOT} />
          <Route exact={true} component={Start} path={NavigationRoute.START} />
          <Route component={VacancyDetail} path={NavigationRoute.VACANCY_DETAIL + '/:key'} />
          <Route component={CompanyMission} path={NavigationRoute.COMPANY_MISSION} />
          <Route component={Success} path={NavigationRoute.SUCCESS} />
          <Route component={DataProtection} path={NavigationRoute.DATA_PROTECTION} />
          <Route component={() => <Quiz page={QuizPages.YOUR_EXPERIENCE} />} path={NavigationRoute.QUIZ_EXPERIENCE} />
          <Route
            component={() => <Quiz page={QuizPages.MORE_ABOUT_YOURSELF} />}
            path={NavigationRoute.QUIZ_MORE_ABOUT_YOURSELF}
          />
          <Route
            component={() => <Quiz page={QuizPages.YOUR_CONTACT_DETAILS} />}
            path={NavigationRoute.QUIZ_YOUR_CONTACT_DETAILS}
          />
        </SlideRoutes>
      </ScrollToTop>
    </AppWarpper>
  );
}

export default App;

const AppWarpper = styled.div`
  min-width: 335px;
  max-width: 400px;
  margin: 0 auto;
  overflow-x: hidden;
`;
