import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../styles/Theme';

export interface ProgressBarProps {
  currentIndex: number;
  length: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps) => {
  const indicatorWidth = (100 / props.length) * props.currentIndex + '%';

  return (
    <>
      <ProgressBarWrapper>
        <ProgressBarIndicator style={{ width: indicatorWidth }}></ProgressBarIndicator>
      </ProgressBarWrapper>
      <ProgressBarCounter>
        {props.currentIndex}/{props.length}
      </ProgressBarCounter>
    </>
  );
};

const ProgressBarWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;
  height: 5px;
  background: ${colors.secondary.white50};
  border-radius: 5px;
`;

const ProgressBarIndicator = styled.div`
  position: relative;
  height: 5px;
  background: ${colors.basic.WHITE};
  border-radius: 5px;
  transition: width 0.2s ease-in;
`;

const ProgressBarCounter = styled.div`
  padding-top: 5px;
  color: ${colors.basic.WHITE};
  line-height: 14px;
  font-size: 10px;
  font-weight: 600;
  font-family: ${fonts.SharpGroteskSemiBold19};
  text-align: right;
`;
